import React, { useEffect, useState } from "react";

// import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import PatientprofileHeader from "./PatientprofileHeader";
import SinglePatientSideBar from "./SinglePatientSideBar";
import Modal from "../../../Modal";
import Rides from "./Rides";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { MdLinkedCamera } from "react-icons/md";
import { toast,ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Email } from "@mui/icons-material";
import { usePatientContext } from "../../../../Contexts/PatientContext";

const AddPatient = () => {
 const{allPatients,setAllPatients}=usePatientContext()
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('success');
  const [message, setMessage] = useState('');
  const[firstName,setFirstName]=useState("")
  const[lastName,setLastName]=useState("")
  const[EMailAddress,setEMailAddress]=useState("")
  const[phoneNumber,setPhoneNumber]=useState("")
  const[location,setLocation]=useState("")
  const[age,setAge]=useState()
  const[gender,setGender]=useState("")
  const[signature,setSignature]=useState(null)
  
  const[companyCode,setComanyCode]=useState("RWL2000")
  const[loading,setLoading]=useState(false)
  let navigate=useNavigate()
let addPatient=async()=>{
  setLoading(true)
  console.log(firstName)
  console.log(lastName)
  console.log(EMailAddress)
  console.log(gender)
  console.log(phoneNumber)
  console.log(companyCode)
  console.log(age)
  const formData=new FormData()
  formData.append("firstName",firstName)
  formData.append("lastName",lastName)
  formData.append("EMailAddress",EMailAddress)
  formData.append("gender",gender)
  formData.append("phoneNumber",phoneNumber)
  formData.append("age",age)
  formData.append("location",location)
  formData.append("profilePhoto",profilePhoto)
  formData.append("signature",signature)
  try{
  const token=localStorage.getItem("token")
  let response=await axios.post("https://api.triphog.net/api/v1/patient/add",formData,{headers:{'Authorization':token,"Content-Type":"multipart/form-data"}})
  console.log(response.data)
  if(response.data.success)
  {
    setAllPatients([...allPatients,response.data.patient])
    setMessage("Successfully Added!")
    setType("success")
    setOpen(true)
    setLoading(false)
  }
  else
  {
    setMessage("There Was An Error While Adding Patient!")
    setType("error")
    setOpen(true)
    setLoading(false)
  }
}
catch(e)
{
  setMessage("Error While Adding Patient")
  setType("error")
  setOpen(true)
  setLoading(false)
}

}
const closeModal = () => {
  setOpen(false);
  navigate("/admin/patient")
};
const[preview,setPreview]=useState("")
  const[profilePhoto,setProfilePhoto]=useState(null)
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePhoto(file); // Set the file to the state
      setPreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };
  const[decodedUser,setDecodedUser]=useState({});

  useEffect(()=>{
    const token = localStorage.getItem("token");
     
     
     if (token) {
       try {
         const decoded = jwtDecode(token);
     
         // Check if the token is expired
         const currentTime = Date.now() / 1000; // Convert to seconds
         if (decoded.exp && decoded.exp > currentTime) {
           setDecodedUser(decoded)
         } else {
           console.log("Token has expired");
           navigate("/admin/login")
         }
       } catch (error) {
         navigate("/admin/login")
         console.log("Invalid token", error);
       }
     }
     
     if (decodedUser) {
       console.log("Decoded User:", decodedUser);
     } else {
       navigate("/admin/login")
       console.log("No valid token available, user not decoded.");
     }

  },[navigate])


  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  if(decodedUser.role=="User" &&(!checkPermission("Access To Add Patient")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  else{


  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
      <button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",margin:"1px"}}>BACK</h5>
          
          </button>
     <ToastContainer/>
     <div class="w-full rounded-sm  bg-cover bg-center bg-no-repeat items-center">
            <label
              for="upload_profile"
              style={{ backgroundImage: `url(${preview || 'default_avatar_url'})` }} // Display preview or a default avatar
              class="mx-auto cursor-pointer flex justify-center w-[141px] h-[141px] bg-blue-300/20 rounded-full bg-cover bg-center bg-no-repeat"
            >
              <div class="bg-white/90 rounded-full w-6 h-6 text-center ml-28 mt-4">
                <input
                  type="file"
                  name="photo"
                  id="upload_profile"
                  hidden
                  accept="image/*" // Accept only image files
                  onChange={handlePhotoChange} // Handle file change event
                />
                <label for="upload_profile">
                  <span className="text-[25px] text-deepBlue">
                    <MdLinkedCamera />
                  </span>
                </label>
              </div>
            </label>
          </div> 
          <div>
  <form className="text-darkgray laptop:text-lap_b">
    <div className="laptop:flex gap-16 laptop:my-[16px]">
      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
        <label>First Name</label>
        <br />
        <input
          type="text"
          placeholder={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      </div>
      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
        <label>Last Name</label>
        <br />
        <input
          type="text"
          placeholder={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      </div>
    </div>

    <div className="laptop:flex gap-16 laptop:my-[16px]">
      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
        <label>Your Email</label>
        <br />
        <input
          type="text"
          placeholder={EMailAddress}
          onChange={(event) => {
            setEMailAddress(event.target.value);
          }}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      </div>
      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
        <label>Phone Number</label>
        <br />
        <input
          type="text"
          placeholder={phoneNumber}
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      </div>
    </div>

    <div className="laptop:flex gap-16 laptop:my-[16px]">
      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
        <label>Location</label>
        <br />
        <input
          type="text"
          placeholder={location}
          onChange={(event) => {
            setLocation(event.target.value);
          }}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      </div>
      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
        <label>Gender</label>
        <br />
        <select
          value={gender}
          onChange={(event) => {
            setGender(event.target.value);
          }}
          className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        >
          <option selected>Select Gender</option>
          <option value={"male"}>Male</option>
          <option value={"female"}>Female</option>
        </select>
      </div>
    </div>

    <div className="laptop:flex gap-16 laptop:my-[16px]">
      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
        <label>Age</label>
        <br />
        <input
          type="number"
          placeholder={age}
          onChange={(event) => {
            setAge(event.target.value);
          }}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      </div>

      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
        <label>Signature</label>
        <br />
        {signature != null ? (
          <img
            src={URL.createObjectURL(signature)}
            style={{ height: "100px", width: "150px", borderRadius: "5px" }}
          />
        ) : (
          <></>
        )}
        <input
          type="file"
          onChange={(event) => {
            setSignature(event.target.files[0]);
          }}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      </div>
    </div>

    <div className="laptop:flex gap-16 laptop:my-[16px]">
      <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
        <label>Company Code</label>
        <br />
        <input
          type="text"
          value={decodedUser.companyCode}
          disabled
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      </div>
    </div>

          {/* Submit Button */}
                  <div className="flex justify-center items-center mt-8">
                    {loading ? (
                      <button className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-full py-[12px] px-[50px] flex items-center justify-center">
                        <CircularProgress style={{ color: "white", height: "20px", width: "20px" }} thickness={8} />
                      </button>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          addPatient();
                        }}
                        type="submit"
                        className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-full py-[12px] px-[50px] transition-all"
                      >
                        Submit
                      </button>
                    )}
                  </div>

    <div className="flex flex-col items-center justify-center h-screen space-y-4">
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full shadow-lg">
            <div className="flex flex-col items-center">
              {type === "success" ? (
                <svg
                  className="w-12 h-12 text-green-500 mb-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="w-12 h-12 text-red-500 mb-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              )}
              <p className="text-lg font-semibold mb-2">
                {type === "success"
                  ? "Patient Added Successfully!"
                  : "Something went wrong!"}
              </p>
              <button
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  </form>
</div>

    
      </div>

      <div className="bg-white laptop:mt-[-80px]">
      
      </div>
      <>
        {/* {status && (
          <Modal status={status} setStatus={setStatus} message={modalMessage} />
        )} */}
      </>
    </>
  );
};}

export default AddPatient;
