import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// Create the context
const DriverContext = createContext();
// Custom provider component
export const DriverProvider = ({ children }) => {
    const navigate=useNavigate()

 const[allDrivers,setAllDrivers]=useState([])
 const token=localStorage.getItem("token")
 const getAllDrivers=async()=>{
    try{
        const response=await axios.get(
            `https://api.triphog.net/api/v1/driver/getdrivers`,
            { headers: { Authorization: token } }
          );
          console.log("Drivers Context Response",response.data)
          if(response.data.success){
            setAllDrivers(response.data.drivers)
          }
          else{
            console.error("Error fetching drivers!")
          }
    }
    catch(e){
        console.error(e.response?e.response.data.message:e.message)
    }
 }
 useEffect(()=>{
    if(token)
    {
    const decodedToken=jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
            
    if (decodedToken.exp && decodedToken.exp > currentTime) {
     getAllDrivers()
                      
  }
   else
   {
    navigate("/admin/login");
   }
    }

 },[token])
  return (
    <DriverContext.Provider value={{allDrivers,setAllDrivers}}>
      {children}
    </DriverContext.Provider>
  );
};

// Custom hook for using the context
export const useDriverContext = () => useContext(DriverContext);
