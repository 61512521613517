import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import "./index.css";
import App from "./App";
import { SocketProvider } from "./components/SocketContext/SocketContext";
import { ReceiverIdProvider } from "./components/Admin/Chat/ChatReceiverContext";
import { AdminProvider } from "./components/Admin/AdminContext/AdminContext";
import { TripProvider } from "./Contexts/TripContext";
import { DriverProvider } from "./Contexts/DriverContext";
import { PatientProvider } from "./Contexts/PatientContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter> {/* Wrap App with BrowserRouter */}
  <SocketProvider>
    <ReceiverIdProvider>
    <AdminProvider>
      <TripProvider>
      <DriverProvider>
        <PatientProvider>  
    <App/>
    </PatientProvider>
    </DriverProvider>
    </TripProvider>

    </AdminProvider>
    </ReceiverIdProvider>
</SocketProvider>
  </BrowserRouter>
  

)
/*

*/