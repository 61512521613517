import { IoArrowBackOutline } from "react-icons/io5";
// import { useAddNewAdminContext } from "../../../../Providers/SuperAdmin/AddNewAdminProvider";
import { MdLinkedCamera } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
// import AdminSideBar from "./AddNewAdminSideBar";
import { useLoadScript,Autocomplete } from "@react-google-maps/api";
import Modal from "../../../Modal";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { usePatientContext } from "../../../../Contexts/PatientContext";
import { useTripContext } from "../../../../Contexts/TripContext";
const libraries = ['places'];

const EditRide = () => {
  const{allTrips,setAllTrips}=useTripContext()
  const{allPatients}=usePatientContext()
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCRDiod9UUH0SHTiIDg2k3fW_FtO0EekSg',
    libraries,
  });
  const pickupAutocompleteRef = useRef();
  const dropoffAutocompleteRef = useRef();
  
  const onLoadPickup = (autocomplete) => {
    pickupAutocompleteRef.current = autocomplete;
  };
  
  const onLoadDropoff = (autocomplete) => {
    dropoffAutocompleteRef.current = autocomplete;
  };
  
  const onPlaceChangedPickup = () => {
    if (pickupAutocompleteRef.current !== null) {
      const place = pickupAutocompleteRef.current.getPlace();
      setPickUpAddress(place.formatted_address);
    }
  };
  
  const onPlaceChangedDropoff = () => {
    if (dropoffAutocompleteRef.current !== null) {
      const place = dropoffAutocompleteRef.current.getPlace();
      setDropOffAddress(place.formatted_address);
    }
  };
  
  const [isOtherTrip, setIsOtherTrip] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [patientPhoneNumber, setPatientPhoneNumber] = useState("");
  const [patientRef, setPatientRef] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [pickUpTime, setPickUpTime] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [pickUpAddress, setPickUpAddress] = useState("");
  const [dropOffAddress, setDropOffAddress] = useState("");
  const [status, setStatus] = useState("");
  const [legId, setLegId] = useState("");
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [patientType, setPatientType] = useState("");
  const [patients, setPatients] = useState([]);
  let navigate = useNavigate();
  
  let getTripById = useCallback(async () => {
    setIsLoading(true);
    try {
     const response = await axios.get(
        `https://api.triphog.net/api/v1/trip/gettripbyId/${id}`,
        { headers: { Authorization: localStorage.getItem("token") } }
      );
      console.log("Trip Edit Detail", response.data);
      setPatientName(response.data.trip.patientName);
      setPatientPhoneNumber(response.data.trip.patientPhoneNumber);
      setPatientRef(response.data.trip.patientRef);
      console.log("Pick UP Date Of Trip", response.data.trip.pickUpDate);
      setPickUpDate(response.data.trip.pickUpDate);
      setPickUpTime(response.data.trip.pickUpTime);
      setAppointmentTime(response.data.trip.appointmentTime);
      setPickUpAddress(response.data.trip.pickUpAddress);
      setDropOffAddress(response.data.trip.dropOffAddress);
      setLegId(response.data.trip.legId);
      setPatientType(response.data.trip.patientType);
      setStatus(response.data.trip.status);
      setIsOtherTrip(response.data.trip.isOtherTrip);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast.error("Something Went Wrong While Getting Trip!");
    }
  }, [id]);
  
  let getAllPatients = useCallback(async () => {
    if(allPatients){
      setPatients(allPatients)
    }
  }, [allPatients]);
  
  const formatDateForInput = (dateString) => {
    let date;
    if (dateString.includes('/')) {
      date = dateString.split('/');
    } else if (dateString.includes('-')) {
      date = dateString.split('-');
    }
    const year = date[0];
    const month = date[1].padStart(2, '0');
    const day = date[2].padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };
  
  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    const [year, month, day] = dateValue.split('-');
    setPickUpDate(`${year}/${parseInt(month)}/${parseInt(day)}`);
  };
  
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllPatients();
    getTripById();
  }, [getAllPatients, getTripById]);
  
  let updateTrip = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      let response = await axios.put(
        `https://api.triphog.net/api/v1/trip/update/${id}`,
        {
          patientName,
          patientPhoneNumber,
          patientRef,
          patientType,
          pickUpDate,
          pickUpTime,
          appointmentTime,
          legId,
          pickUpAddress,
          dropOffAddress,
          status,
        },
        { headers: { Authorization: token } }
      );
      console.log("Trip Edit Response", response.data);
      if (response.data.success) {
        setAllTrips((prevTrips) =>
          prevTrips.map((trip) =>
            trip._id === response.data.updatedTrip._id ? response.data.updatedTrip : trip
          )
        );
        toast.success("Successfully updated trip!", { position: "top-right",style:{fontWeight:"bold",fontFamily:"Cabin"}});
        setLoading(false);
        setTimeout(() => {
          navigate("/admin/trips");
        }, 2000);
      } else {
        toast.error("Trip could not be updated!", { position: "top-right",style:{fontWeight:"bold",fontFamily:"Cabin"} });
        setLoading(false);
      }
    } catch (e) {
      toast.error("Trip could not be updated!",{position:"top-right",style:{fontWeight:"bold",fontFamily:"Cabin"}});
    }
  };
  
  const token = localStorage.getItem("token");
  const [decodedUser, setDecodedUser] = useState({});
  
  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp && decoded.exp > currentTime) {
          setDecodedUser(decoded);
        } else {
          console.log("Token has expired");
          navigate("/admin/login");
        }
      } catch (error) {
        navigate("/admin/login");
        console.log("Invalid token", error);
      }
    } else {
      navigate("/admin/login");
      console.log("No valid token available, user not decoded.");
    }
  }, [token, navigate]);
  
  let checkPermission = (permission) => {
    console.log("Decoded User Permissions", decodedUser);
    if (decodedUser.role === "Admin") {
      return false;
    } else {
      return decodedUser.accessibilities.includes(permission);
    }
  };
  
  const getValidDate = (date) => {
    const [month, day, year] = pickUpDate.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };
  
  const handleGoBack = () => {
    navigate(-1);
  };
  if(decodedUser.role=="User" &&(!checkPermission("Access To Edit Trip")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  else
  {
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;
if(isLoading){
  return(<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <CircularProgress size={60} thickness={5} color="inherit" />
  </div>)
}
 else{
  return (
    <>
      <div className="">
     
        <ToastContainer/>
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
        <button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",margin:"1px"}}>BACK</h5>
          
          </button>
          <div class="w-full rounded-sm  bg-cover bg-center bg-no-repeat items-center">
            
            
          </div>
          <form
            className="text-darkgray laptop:text-lap_b text-[30px]"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            {isOtherTrip?<input  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white" value={patientName} onChange={(e)=>{
              setPatientName(e.target.value)
            }} />:

            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Patient Ref</label>
                <br />
                <select
                value={patientRef}
                   onChange={(event) => {
                    let patientId=event.target.value
                    if(event.target.value!="Choose Patient By Ref")
                    {
                      let _patients=patients.filter((patient)=>{return(patient._id==patientId)})
                    let Patient=_patients[0]
                    setPatientName(Patient.firstName+" "+Patient.lastName)
                    setPatientPhoneNumber(Patient.phoneNumber)
                     setPatientRef(event.target.value)

                    }
                    
                   }}
                  className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option value="Choose Patient By Ref">Choose Patient By Ref</option>
        
                  {
                    patients.map((patient)=>{
                      return(<option value={patient._id}>{patient._id}</option>)
                    })
                  }

                </select>
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Patient Name (Full)</label>
                <br />
                <select
                  type="text"
                  
                value={patientRef}
                   onChange={(event) => {
                    let patientId=event.target.value
                    if(event.target.value!="Choose Patient By Name")
                    {
                      let _patients=patients.filter((patient)=>{return(patient._id==patientId)})
                    let Patient=_patients[0]
                    setPatientName(Patient.firstName+" "+Patient.lastName)
                    setPatientPhoneNumber(Patient.phoneNumber)
                     setPatientRef(event.target.value) 

                    }
                    
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option value="Choose Patient By Name">Choose Patient By Name</option>
                  {
                    patients.map((patient)=>{
                     return(<option value={patient._id}>
                      {patient.firstName+" "+patient.lastName}
                     </option>) 
                    })
                  }
                </select>
              </div>
            </div>
 }
          
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Patient Phone number</label>
                <br />
                <input
                  type="Number"
                  placeholder="546-933-2772"
                  value={patientPhoneNumber}
                   onChange={(event) => {
                     setPatientPhoneNumber(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Patient Type</label>
                <br />
                <select
                value={patientType}
                   onChange={(event) => {
                     setPatientType(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option disabled>Patient Type</option>
                  <option  value="Wheel Chair">
                    Wheel Chair
                  </option>
                  <option value="Normal">Normal</option>
                </select>
              </div>
            </div>
                       
            {/* <div className="flex items-center justify-between">
  <div className="laptop:w-[45%] py-[5px] laptop:text-lap_b text-[30px]">
    <label>Leg ID</label>
    <br />
    <input
      value={legId}
      type="text"
      placeholder="Q5469332171"
      onChange={(event) => {
        setLegId(event.target.value);
      }}
      className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
    />
  </div>
  <div className="laptop:w-[45%] py-[5px] laptop:text-lap_b text-[30px]">
    <label>Appointment Time</label>
    <br />
    <input
      type="time"
      value={appointmentTime}
      onChange={(event) => {
        setAppointmentTime(event.target.value);
      }}
      className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
    />
  </div>
</div> */}
 <div className="laptop:flex gap-16 laptop:my-[16px]">

<div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
  <label>Leg Id </label>
  <br />
  <input
      
      value={legId}
      onChange={(e)=>{
        setLegId(e.target.value)
      }}
      className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
    />




</div>
<div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Appointment Time</label>
                <br />
                <input
                value={appointmentTime}
                  type="time"
                   onChange={(event) => {
                     setAppointmentTime(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
</div>

            <div className="laptop:flex gap-16 laptop:my-[16px]">

<div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
  <label>Pickup Date </label>
  <br />
  <input
      type="date"
      value={pickUpDate ? formatDateForInput(pickUpDate) : ''}
      onChange={handleDateChange}
      className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
    />




</div>
<div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Pick Up Time</label>
                <br />
                <input
                value={pickUpTime}
                  type="time"
                   onChange={(event) => {
                     setPickUpTime(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
</div>

              <div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Pickup Address</label>
                <br />
              
                 <Autocomplete onLoad={onLoadPickup} onPlaceChanged={onPlaceChangedPickup}>
                 <input
                 value={pickUpAddress}
                    type="text"
                    placeholder="H/No GH34"
                    onChange={(e)=>{
                      setPickUpAddress(e.target.value)
                    }}
                                      className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                  />
                 </Autocomplete>
                  
                 
                
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Drop off Address</label>
                <br />
                <Autocomplete onLoad={onLoadDropoff} onPlaceChanged={onPlaceChangedDropoff}>
                <input
                value={dropOffAddress}
                  type="text"
                  placeholder="Hospital"
                   onChange={(event) => {
                     setDropOffAddress(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                </Autocomplete>
              </div>
              
            </div>
            <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Status</label>
                <br />
                <select
                value={status}
                
                   onChange={(event) => {
                     setStatus(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option value="No Show">No Show</option>
                  <option value="No Responsive">Non Responsive</option>
                  <option value="Cancelled">Cancel</option>
                  <option value="Assigned">Assigned</option>
                  <option value="On Route">On Route</option>
                  <option value="Not Assigned">Not Assigned</option>
                  <option value="Completed">Completed</option>


                </select>
                
              </div>

            </div> 



             

            {/* Submit Button */}
  <div className="flex justify-center items-center mt-8">
    {loading ? (
      <button className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-full py-[12px] px-[50px] flex items-center justify-center">
        <CircularProgress style={{ color: "white", height: "20px", width: "20px" }} thickness={8} />
      </button>
    ) : (
      <button
        onClick={(e) => {
          e.preventDefault();
          updateTrip()
        }}
        type="submit"
        className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-full py-[12px] px-[50px] transition-all"
      >
        Submit
      </button>
    )}
  </div>
          </form>
        </div>
      </div>
      <div className="mt-[-100px] bg-[white]">{/* <AdminSideBar /> */}</div>
      {/* {status && (
        <Modal status={status} setStatus={setStatus} message={message} />

      )} */}
    </>
  );}
};}


export default EditRide;

/*
Udemy Clone Using NextJs
Amazon Clone Using Next Js,Stripe Payment Integration
Amazon Platform For Buyers And Sellers
UpWork Clone Freelance Market Place Just Like UpWork
Fiverr Clone Using Next Js
Chat Web App Using Next js
One To One Chat,Seen UnSeen Messages,Notifications, Groups

Full Stack Social Media Web App Just Like Linked In Using MERN Stack

Let's Take A Look At
*/
