import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useSocket } from "../../SocketContext/SocketContext";

// Default map container style
const mapContainerStyle = {
  width: "100%",
  height: "350px",
};

// Set default center to Louisiana, USA
const defaultCenter = {
  lat: 30.9843,
  lng: -91.9623,
};

const Map = () => {
  const navigate = useNavigate();
  const socket=useSocket()
  console.log("Socket Instance",socket)
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const token = localStorage.getItem("token");

  // Fetch drivers data from the API
  const getDrivers = async () => {
    try {
      let response = await axios.get(
        "https://api.triphog.net/api/v1/driver/getdrivers",
        {
          headers: { Authorization: token },
        }
      );
      console.log("Fetched Drivers:", response.data.drivers);
      setDrivers(response.data.drivers);
    } catch (e) {
      console.error("Failed to fetch drivers", e);
    }
  };

  // Handle location updates from the socket
  useEffect(() => {
    if (socket) {
      const handleLocationChange = (data) => {
        console.log("Location Of Driver Changed")
        console.log("Driver ID:", data.driverId);
        console.log("Lat:", data.lat);
        console.log("Lng:", data.lng);

        // Update the driver's location if the driverId matches
        setDrivers((prevDrivers) =>
          prevDrivers.map((driver) =>
            driver._id === data.driverId
              ? { ...driver, latitude: data.lat, longitude: data.lng }
              : driver
          )
        );
      };

      // Listen for the "location-changed" event
      socket.on("location-changed", handleLocationChange);

      // Cleanup the socket listener on unmount
      return () => {
        socket.off("location-changed", handleLocationChange);
      };
    }
  }, [socket]);
  

  // Fetch drivers on component mount and set interval
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds

        if (decodedToken.exp && decodedToken.exp > currentTime) {
          getDrivers(); // Fetch drivers initially
          
        } else {
          console.log("Token has expired");
          navigate("/admin/login");
        }
      } catch (error) {
        console.log("Invalid token", error);
        navigate("/admin/login");
      }
    } else {
      navigate("/admin/login");
    }
  }, [navigate]);

  return (
    <div className="bg-white rounded-[20px] p-[20px] mt-[20px] laptop:mr-[30px] shadow-md">
      <h2 className="font-semibold text-lg text-gray-800 my-[10px]">Map View</h2>
      <h5 className="text-gray-600 mb-4">Drivers On Map</h5>

      {/* Load Google Maps API */}
      <LoadScript googleMapsApiKey="AIzaSyCRDiod9UUH0SHTiIDg2k3fW_FtO0EekSg">
        <GoogleMap
          mapContainerStyle={{
            height: "400px",
            width: "100%",
            borderRadius: "20px",
            overflow: "hidden",
          }}
          zoom={5}
          center={defaultCenter}
        >
          {/* Display a marker for each driver with valid coordinates */}
          {drivers
            .filter((driver) => {
              const validLat = !isNaN(driver.latitude) && driver.latitude !== 0;
              const validLng = !isNaN(driver.longitude) && driver.longitude !== 0;
              return validLat && validLng;
            })
            .map((driver, index) => (
              <Marker
                key={index}
                position={{ lat: driver.latitude, lng: driver.longitude }}
                onClick={() => setSelectedDriver(driver)}
              />
            ))}

          {/* Display InfoWindow when a marker is clicked */}
          {selectedDriver && (
            <InfoWindow
              position={{
                lat: selectedDriver.latitude,
                lng: selectedDriver.longitude,
              }}
              onCloseClick={() => setSelectedDriver(null)} // Close the InfoWindow
            >
              <div className="text-center flex flex-col justify-center items-center">
                {/* Driver's photo */}
                {selectedDriver.profilePhotoUrl && (
                  <img
                    src={selectedDriver.profilePhotoUrl}
                    alt={`${selectedDriver.firstName} ${selectedDriver.lastName}`}
                    className="w-[65px] h-[65px] rounded-full mb-2"
                  />
                )}
                <h1 className="text-lg font-medium">{`${selectedDriver.firstName} ${selectedDriver.lastName}`}</h1>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Map;