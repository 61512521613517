import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { Box,CircularProgress } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import AdminChat from '../Chat/AdminChat';
import { AdminContext } from '../AdminContext/AdminContext';

const MyDocuments = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [docs, setDocs] = useState([]);
  const {admin}=useContext(AdminContext)
  const [uploading, setUploading] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState(admin?.plan!="Basic"?true:false);
  const [deletingDocId, setDeletingDocId] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadMessage, setDownloadMessage] = useState('');
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [hasBasicPlan,setHasBasicPlan]=useState(admin?.plan=="Basic")

  const fileInputRef = useRef(null);
  

  const fetchDocs = async () => {
    setLoadingDocs(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api.triphog.net/api/v1/admin/getdocs', {
        headers: { Authorization: token },
      });
      if (response.data.success) {
        setDocs(response.data.docs);
      } else {
        toast.error('Failed to fetch documents');
      }
    } catch (error) {
      toast.error('Error fetching documents');
    } finally {
      setLoadingDocs(false);
    }
  };
  const[isUser,setIsUser]=useState(false)
  const navigate=useNavigate()
  
  

  useEffect(() => {
    const token = localStorage.getItem("token");
        
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
        
                if (decodedToken.exp && decodedToken.exp > currentTime) {
       if(decodedToken.role=="Admin" &&admin?.plan!="Basic"){
    fetchDocs();
       }
       else{
        setIsUser(true)
       } 
                  
                } else {
                  console.log("Token has expired");
                  navigate("/admin/login");
                }
              } catch (error) {
                console.log("Invalid token", error);
                navigate("/admin/login");
              }
            } else {
              navigate("/admin/login");
            }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
  };

  const handleSave = async () => {
    if (!selectedFile) {
      toast.error('No file selected');
      return;
    }
    const formData = new FormData();
    formData.append('document', selectedFile);
    formData.append('docName', selectedFile.name + selectedFile.type.split('/')[1]);
    setUploading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://api.triphog.net/api/v1/admin/adddoc', formData, {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: token },
      });
      if (response.data.success) {
        toast.success('File uploaded successfully!');
        fetchDocs();
      } else {
        toast.error('Error uploading file');
      }
    } catch (error) {
      toast.error('Error uploading file');
    } finally {
      setUploading(false);
      setSelectedFile(null);
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteDoc = async (docId) => {
    setDeletingDocId(docId);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`https://api.triphog.net/api/v1/admin/deletedoc/${docId}`, {
        headers: { Authorization: token },
      });
      if (response.data.success) {
        toast.success('Document deleted successfully!');
        fetchDocs();
      } else {
        toast.error('Error deleting document');
      }
    } catch (error) {
      toast.error('Error deleting document');
    } finally {
      setDeletingDocId(null);
    }
  };

  const handleDownloadDoc = async (url) => {
    setShowProgressBar(true);
    setDownloadProgress(0);
    setDownloadMessage('Preparing to download...');
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(url, {
        headers: { Authorization: token },
        responseType: 'blob', // Important for file download
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setDownloadProgress(percentCompleted);
        },
      });
      saveAs(response.data, url.split('/').pop()); // Direct download using file-saver
      setDownloadMessage('Download completed!'); // Show completed message
      setTimeout(() => {
        setDownloadMessage('');
        setShowProgressBar(false);
      }, 1000); // Clear message after 1 second
    } catch (error) {
      toast.error('Error downloading document');
      setShowProgressBar(false);
    }
  };

  const handlePreviewDoc = (url) => {
    window.open(url, '_blank');
  };
  if(loadingDocs)
  {
    return(
      <Box sx={{height:"100vh", width:"100vw",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
        <CircularProgress sx={{color:"black",height:"15px",width:"15px"}} thickness={7}/>

      </Box>
    )
  }
 else  if(hasBasicPlan || isUser)
  {
    return(
      <div className="flex items-center justify-center h-screen bg-deepBlue w-[1100px] mt-[20px] rounded-md overflow-hidden">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
        <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
        <p className="text-gray-700 mb-6">
          You do not have access to this page. Please contact the administrator or go back.
        </p>
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
        >
          Go Back
        </button>
      </div>
    </div>
    
    )
  }
  else{
  return (
    <div className="min-h-screen flex flex-col items-center justify-start bg-white p-6 font-cabin w-full">
      <ToastContainer />
      <div className="w-full max-w-5xl bg-white shadow-lg p-6 rounded-lg">
        <div className="flex justify-center items-center mb-6">
          <button
            onClick={triggerFileInput}
            className="bg-deepBlue text-white px-6 py-3 rounded-full flex items-center"
          >
            <FaPlus className="mr-2" /> Add Document
          </button>
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </div>

        {selectedFile && (
          <div className="bg-gray-100 shadow-lg rounded-lg p-4 mb-6 w-full max-w-lg relative">
            <p className="font-bold text-lg text-deepBlue text-center">{selectedFile.name}</p>
            <div className="flex flex-col space-y-2 mt-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-deepBlue text-white rounded-full hover:bg-blue-700 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                disabled={uploading}
                className={`px-4 py-2 bg-deepBlue text-white rounded-full ${uploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700 transition'}`}
              >
                {uploading ? 'Uploading...' : 'Save'}
              </button>
            </div>
            <button
              onClick={handleCancel}
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
            >
              <FaTimes />
            </button>
          </div>
        )}

        {loadingDocs ? (
          <p className="text-gray-500">Loading documents...</p>
        ) : docs.length === 0 ? (
          <p className="text-center text-gray-500">No Docs Available</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {docs.map((doc) => (
              <div
                key={doc.Id}
                className="relative flex flex-col justify-between p-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow transform hover:scale-105"
                style={{ minHeight: '200px', maxWidth: '100%' }}
              >
                <div className="flex flex-col justify-center items-center h-full">
                  <p className="font-bold text-lg text-deepBlue text-center">{doc.title}</p>
                </div>

                <div className="flex justify-between space-x-4 mt-4">
                  <button
                    onClick={() => handlePreviewDoc(doc.url)}
                    className="px-4 py-2 bg-deepBlue text-white rounded-full hover:bg-blue-700 transition"
                  >
                    Preview
                  </button>
                  <button
                    onClick={() => handleDownloadDoc(doc.url)}
                    className="px-4 py-2 bg-deepBlue text-white rounded-full hover:bg-blue-700 transition"
                  >
                    Download
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => handleDeleteDoc(doc.Id)}
                    disabled={deletingDocId === doc.Id}
                  >
                    {deletingDocId === doc.Id ? (
                      <span>Deleting...</span>
                    ) : (
                      <FaTimes />
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {showProgressBar && (
          <div className="mt-4">
            <div className="relative w-full bg-gray-300 rounded-full h-2">
              <div
                className="bg-deepBlue h-2 rounded-full"
                style={{ width: `${downloadProgress}%` }}
              ></div>
            </div>
            {downloadMessage && (
              <p className="text-center text-green-500 mt-2">{downloadMessage}</p>
            )}
          </div>
        )}
      </div>
    </div>
  )}
};

export default MyDocuments;
