import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// Create the context
const PatientContext = createContext();
// Custom provider component
export const PatientProvider = ({ children }) => {
    const navigate=useNavigate()
 const[allPatients,setAllPatients]=useState([])
 const token=localStorage.getItem("token")

 const getAllPatients=async()=>{
    try{
        const response=await axios.get(
            `https://api.triphog.net/api/v1/patient/getall`,
            { headers: { Authorization: token } } 
          );
          if(response.data.success){
            setAllPatients(response.data.patients)
          }
          console.log("Patients Context Response",response.data)
    }
    catch(e){
        console.error(e.response?e.response.data.message:e.message)
    }
 }
 useEffect(()=>{
    if(token)
    {
    const decodedToken=jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
            
    if (decodedToken.exp && decodedToken.exp > currentTime) {
     getAllPatients()
                      
  }
   else
   {
    navigate("/admin/login");
   }
    }
  
 },[token])
  return (
    <PatientContext.Provider value={{allPatients,setAllPatients}}>
      {children}
    </PatientContext.Provider>
  );
};

// Custom hook for using the context
export const usePatientContext = () => useContext(PatientContext);
