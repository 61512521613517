import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Input,
  Snackbar,
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Card,
  CardContent,
  Modal,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Chip,
  CircularProgress,
} from "@mui/material";
import {
  Send,
  AttachFile,
  Add,
  Close,
  ChatOutlined,
  ConstructionOutlined,
} from "@mui/icons-material";
import axios from "axios";

import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useSocket } from "../../SocketContext/SocketContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useReceiverId } from "./ChatReceiverContext";
import { useChatReceiverId } from "../../SuperAdmin/SuperAdminChatReceiverContext/SuperAdminChatReceiverContext";;

const AdminChat = () => {
  const socket = useSocket();
  console.log("My Socket Instance", socket);
  const [isChatsLoading, setIsChatsLoading] = useState(true);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const location =useLocation()
  const [drivers, setDrivers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [chats, setChats] = useState([]);
  const [allChats, setAllChats] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [text, setText] = useState("");
  const{receiverId,updateReceiverId}=useReceiverId()
  const [chatMessages, setChatMessages] = useState([]);
  const [groupChatMessages, setGroupChatMessages] = useState([]);
  const [groupTitle, setGroupTitle] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isUsersModelOpen, setIsUsersModelOpen] = useState(false);
  const [allDrivers, setAllDrivers] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [superAdmins, setSuperAdmins] = useState([]);
  const[isSendingMessage,setIsSendingMessage]=useState(false)

  let SELECTEDRECEIVERID=""
  let getChatMessages = async (ReceiverId) => {
    console.log("GETTIN CHAT MSGS FOR RECEIVER",ReceiverId)
    if(ReceiverId)
    {
    setIsChatLoading(true);
    try {
      console.log("RECEIVER ID",ReceiverId);
      console.log("RECEIVED Id",SELECTEDRECEIVERID)
      console.log("Getting Chat Messages For Receiver Id",ReceiverId)
      let response = await axios.get(
        `https://api.triphog.net/api/v1/chat/getmessages/${
          decodedUser.role == "User"
            ? decodedUser.createdBy
            : localStorage.getItem("adminId")
        }/${ReceiverId}`
      );
      console.log(response.data);
      setChatMessages(response.data.messages);
      setIsChatLoading(false);
    } catch (e) {
      console.error("Error",e.message)
      toast.error("Error While Getting Messages");
    }
  }
  };
  
  const token = localStorage.getItem("token");
     
     let decodedUser = {};
     
     if (token) {
       try {
         const decoded = jwtDecode(token);
     
         // Check if the token is expired
         const currentTime = Date.now() / 1000; // Convert to seconds
         if (decoded.exp && decoded.exp > currentTime) {
           decodedUser = decoded;
         } else {
           console.log("Token has expired");
           navigate("/admin/login")
         }
       } catch (error) {
         navigate("/admin/login")
         console.log("Invalid token", error);
       }
     }
     
     if (decodedUser) {
       console.log("Decoded User:", decodedUser);
     } else {
       navigate("/admin/login")
       console.log("No valid token available, user not decoded.");
     }
  
  const [isGroupChat, setIsGroupChat] = useState(false);
  const [isOneToOneChat, setIsOneToOneChat] = useState(false);
  
  const [selectedReceiverId, setSelectedReceiverId] = useState(null);
  const getMyChats = async () => {
    setIsChatsLoading(true);
    try {
      let response = await axios.get(
        "https://api.triphog.net/api/v1/chat/getmychats",
        { headers: { Authorization: localStorage.getItem("token") } }
      );
      console.log(response.data);
      const sortedChats = response.data.chats.sort((a, b) => {
        const dateA = new Date(a.lastMessage.addedON);
        const dateB = new Date(b.lastMessage.addedON);
        return dateB - dateA; // Sort descending (latest first)
      });
      console.log("Sorted Chats For Admin",sortedChats)
      setChats(sortedChats);
      setAllChats(sortedChats);
      
      setIsChatsLoading(false);
    } catch (e) {
      toast.error("Error While Getting Chats");
      setIsChatsLoading(false);
    }
  };
  let getAllUsers = async () => {
    try {
      const token = localStorage.getItem("token");
      let response = await axios.get(
        "https://api.triphog.net/api/v1/admin/getallusers",
        { headers: { Authorization: token } }
      );
      console.log(response.data);
      setAllDrivers(response.data.allDrivers);
      setAllPatients(response.data.allPatients);
      console.log("Super Admins", response.data);
      setSuperAdmins(response.data.superAdmins);
    } catch (e) {
      toast.error("Error While Getting Users For Chatting");
    }
  };
  let handleCloseUsersModal = () => {
    setIsUsersModelOpen(false);
  };
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackBarOpen(false);
  };
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const handleDriverToggle = (driver) => {
    const currentIndex = selectedDrivers.findIndex(
      (selectedDriver) => selectedDriver._id === driver._id
    );
    const newSelectedDrivers = [...selectedDrivers];

    if (currentIndex === -1) {
      newSelectedDrivers.push(driver);
    } else {
      newSelectedDrivers.splice(currentIndex, 1);
    }

    setSelectedDrivers(newSelectedDrivers);
  };
  useEffect(()=>{
    updateReceiverId(null)

  },[])
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);
 


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const navigate=useNavigate()

  const handleSendMessage = async () => {
  setIsSendingMessage(true)
    const date = new Date();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Add leading zero to minutes if it's less than 10
    const minutesWithLeadingZero = minutes < 10 ? "0" + minutes : minutes;

    // Format the time as a string
    const at = hours + ":" + minutesWithLeadingZero + " " + ampm;
    if (isOneToOneChat == true && isGroupChat == false) {
      const formData = new FormData();
      console.log("One To One Chat");
      console.log("Today's Date", new Date().getHours());
      if (selectedFile) {
        try {
          console.log("Sendming Media Message");
          formData.append("file", selectedFile);
          formData.append("mediaType", selectedFile.type.split("/")[0]);
          formData.append("at", at);
          formData.append("text", "");
          formData.append(
            "senderName",
            decodedUser.role == "Admin"
              ? decodedUser.firstName + " " + decodedUser.lastName
              : decodedUser.fullName
          );
          let response = await axios.post(
            `https://api.triphog.net/api/v1/chat/sendmessage/${
              decodedUser.role == "User"
                ? decodedUser.createdBy
                : localStorage.getItem("adminId")
            }/${selectedReceiverId}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          console.log(response.data);
          if (response.data.success) {
            setMessage("Successfully Message Sent");
            setIsSnackBarOpen(true);
            setSeverity("success");
            setSelectedFile(null);
            setFilePreview(null);
            getChatMessages(selectedReceiverId);
            getMyChats();
          } else {
            setMessage("Error While Sending Message!");
            setIsSnackBarOpen(true);
            setSeverity("error");
          }
        } catch (e) {
          toast.error("Could Not Send Message");
        }
        finally{
        setIsSendingMessage(false)
        }
        console.log("File to send:", selectedFile);
      } else if (text.length > 0) {
        try {
          formData.append("text", text);
          formData.append("at", at);
          formData.append(
            "senderName",
            decodedUser.role == "Admin"
              ? decodedUser.firstName + " " + decodedUser.lastName
              : decodedUser.fullName
          );

          let response = await axios.post(
            `https://api.triphog.net/api/v1/chat/sendmessage/${
              decodedUser.role == "User"
                ? decodedUser.createdBy
                : localStorage.getItem("adminId")
            }/${selectedReceiverId}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          console.log(response.data);
          if (response.data.success) {
            setMessage("Successfully Message Sent");
            setIsSnackBarOpen(true);
            setSeverity("success");
            setSelectedFile(null);
            setFilePreview(null);
            getChatMessages(selectedReceiverId);
            getMyChats();
          } else {
            setMessage("Error While Sending Message!");
            setIsSnackBarOpen(true);
            setSeverity("error");
          }
        } catch (e) {
          toast.error("Error While Sending Message");
        }
        finally{
        setIsSendingMessage(false)}
      } else {
        console.error("Cannot Send Empty Message");
        setMessage("Cannot Send Emtpy Message");
        setSeverity("info");
        setIsSnackBarOpen(true);
      }
    }
    console.log(selectedFile);

    setSelectedFile(null);
    setFilePreview(null);
  };

  const[isDeletingChat,setIsDeletingChat]=useState(false)
  const messagesEndRef = useRef(null);
  const deleteChat=async()=>{
    setIsDeletingChat(true)
    console.log("Deleting Chat With ReceiverId",receiverId)
    if(receiverId){
      console.log("Deleting Chat With User",receiverId)
    }
    try{
      let response=await axios.delete(`https://api.triphog.net/api/v1/chat/deletechat/${decodedUser.role=="Admin"?decodedUser.id:decodedUser.createdBy}/${selectedReceiverId}`)
      console.log("Deleting Chat Response",response.data)
      if(response.data.success)
      {
        toast.success("Chat Successfully Deleted",{style:{fontFamily:"Cabin"}})
        let _chats= chats.filter((chat)=>{
          return(chat.withId!=selectedReceiverId)
        })
        setChats(_chats)
        setIsOneToOneChat(false)
      
      }
      else{
        toast.error("Chat Could Not Be Deleted Please Try Again!",{style:{fontFamily:"Cabin"}})
      }

    }
        catch(e){
        toast.error("Chat Could Not Be Deleted Please Try Again!",{style:{fontFamily:"Cabin"}})

    }
    finally{
      setIsDeletingChat(false)
    }

  }

// Scroll to the bottom whenever chatMessages updates
useEffect(() => {
  if (socket) {
    // Handler for incoming messages
    const handleMessage = (message) => {
      console.log("PATHNAME", location.pathname);
      console.log("Selected Receiver Id", receiverId);
      console.log("New MESSAGE RECEIVED", message.senderId);

      if (receiverId && receiverId === message.senderId && location.pathname === "/admin/chat") {
        console.log("Getting Chat Msgs");
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        console.log("Your Get MESSAGES Is BEING CALLED FOR ID", message.senderId);
        console.log("IS EQUAL TO RECEIVER", receiverId == message.senderId);
        getChatMessages(message.senderId);
        getMyChats()
      } else {
        console.log("Getting Chats Only");
        getMyChats();
      }
    };

    // Attach listener
    socket.on("newMsg", handleMessage);

    // Attach listener for disconnect event
    socket.on("disconnect", () => {
      console.log("Admin Has Been Disconnected From Socket Server");
    });

    // Cleanup event listeners on unmount or dependency change
    return () => {
      socket.off("newMsg", handleMessage);
      socket.off("disconnect");
    };
  }

  // Ensure scroll to bottom if needed
  if (messagesEndRef.current) {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [receiverId, socket, location.pathname]); // Add location.pathname as a dependency
;


  useEffect(() => {
    //  let socket=io("https://api.triphog.net",{query:{userId:decodedUser.role=="Admin"?localStorage.getItem("adminId"):decodedUser.createdBy}})
    console.log("Admin Has Been Connected To The Socket Server", socket);
    console.log("Admin Path",location.pathname)

    const token = localStorage.getItem("token");
        
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
        
                if (decodedToken.exp && decodedToken.exp > currentTime) {
                
                  getMyChats();
                  getAllUsers();
                  
                } else {
                  console.log("Token has expired");
                  navigate("/admin/login");
                }
              } catch (error) {
                console.log("Invalid token", error);
                navigate("/admin/login");
              }
            } else {
              navigate("/admin/login");
            }
    
  }, []);
  return (
    <Box
      display="flex"
      width="1180px"
      height="100vh"
      bgcolor="white"
      p={2}
      gap={2}
    >
      {/* Left Sidebar */}
      <Paper
  elevation={3}
  style={{
    width: "25%",
    borderRadius: "8px",
    padding: "16px",
    height: "100%", // Full height of the container
    display: "flex",
    flexDirection: "column", // Make the content align vertically
  }}
>
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    mb={2}
  >
    <Typography
      variant="h6"
      style={{ color: "#30325E", fontFamily: "Cabin" }}
    >
      Inquiries
    </Typography>
    <IconButton style={{ color: "#30325E" }} onClick={handleClick}>
      <Add />
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem
        style={{ fontFamily: "Cabin" }}
        onClick={() => {
          setIsUsersModelOpen(true);
        }}
      >
        New Chat
      </MenuItem>
    </Menu>
  </Box>

  <Input
    fullWidth
    style={{ fontFamily: "Cabin" }}
    inputProps={{ style: { fontFamily: "Cabin" } }}
    placeholder="Search chats"
    margin="normal"
    onChange={(e) => {
      let searchedChats = allChats.filter((chat) => {
        return (
          chat.with.includes(e.target.value) ||
          chat.lastMessage.text.includes(e.target.value)
        );
      });
      setChats(searchedChats);
    }}
  />

  {isChatsLoading ? (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "60px",
        alignItems: "center",
        flexGrow: 1, // Makes sure the spinner is centered
      }}
    >
      <CircularProgress
        style={{ color: "#30325E", height: "18px", width: "18px" }}
      />
    </Box>
  ) : chats.length === 0 ? (
    <Typography
      style={{
        fontFamily: "Cabin",
        textAlign: "center",
        marginTop: "50px",
        flexGrow: 1, // Center the "No Chats Yet!" message
      }}
    >
      No Chats Yet!
    </Typography>
  ) : (
    <List
      sx={{
        flexGrow: 1, // Makes the chat list take up remaining space
        overflowY: "auto", // Enables scrolling for the chat list
        // Custom scrollbar styling
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
        },
      }}
    >
      {chats.map((chat) => (
        <Card
          sx={{
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            marginBottom: "10px",
            padding: "8px",
            cursor: "pointer",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
          key={chat.withId}
        >
          <ListItem
            sx={{
              backgroundColor:
                selectedReceiverId === chat.withId && isGroupChat === false
                  ? "#30325E"
                  : "white",
              borderRadius: "5px",
              color:
                selectedReceiverId === chat.withId && isGroupChat === false
                  ? "white"
                  : "black",
              padding: "12px",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
            onClick={async () => {
              try {
                updateReceiverId(chat.withId);
                setIsOneToOneChat(true);
                setIsGroupChat(false);
                setSelectedReceiverId(chat.withId);
                await getChatMessages(chat.withId); // Ensure it waits for this operation
                setReceiverName(chat.withRole!="Super Admin"?chat.with:chat.with=="Wahab Mazhar"?"Technical Support":"HELP");
              } catch (error) {
                console.error("Error occurred in getting chat messages:", error);
              } finally {
                getMyChats(); // This will be called regardless of success or failure
              }
            }}
            
          >
            <ListItemAvatar>
              <Avatar
                sx={{ fontFamily: "Cabin", backgroundColor: "grey" }}
              >
                {chat.with[0]}
              </Avatar>
            </ListItemAvatar>

            <Box
              sx={{
                marginLeft: "10px",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Cabin",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                {chat.withRole=="Super Admin"?chat.with=="Wahab Mazhar"?"Technical Support":"HELP":chat.with}
              </Typography>

              <Typography
                sx={{
                  fontFamily: "Cabin",
                  color: "gray",
                  fontSize: "14px",
                  marginTop: "4px",
                }}
              >
                {chat.lastMessage.text.length > 0
                  ? chat.lastMessage.text.length > 100
                    ? chat.lastMessage.text.substring(0, 100) + "..."
                    : chat.lastMessage.text
                  : chat.lastMessage.mediaUrl.length > 0 &&
                    chat.lastMessage.mediaType === "image"
                  ? "Sent an Image"
                  : "Sent a Video"}
              </Typography>
            </Box>

            {!chat.lastMessage.isRead &&
              chat.lastMessage.senderId !== (decodedUser.role === "Admin" ? decodedUser.id : decodedUser.createdBy) && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#FF3D00",
                    borderRadius: "50%",
                  }}
                />
              )}
          </ListItem>
        </Card>
      ))}
    </List>
  )}
</Paper>


      {/* Main Chat Area */}
      {isOneToOneChat == false && isGroupChat == false ? (
        <Paper
          elevation={3}
          style={{
            flex: 1,
            borderRadius: "8px",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            fontFamily: "Cabin",
          }}
        >
          TRIPHOG
        </Paper>
      ) : isGroupChat == false && isOneToOneChat == true ? (
        <Paper
    elevation={3}
    style={{
      flex: 1,
      borderRadius: "8px",
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      overflow: "scroll",
    }}
  >
    <Box mb={2} sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
      <Typography variant="h6" style={{ fontFamily: "Cabin" }}>
        {receiverName}
      </Typography>
   {chatMessages.length>0 && isDeletingChat==false?<Button className="bg-deepBlue text-deepBlue" sx={{color:"darkgrey",borderRadius:3}} onClick={deleteChat}>DELETE Chat</Button>:isDeletingChat?<CircularProgress sx={{color:"darkgray",width:20}}/>:<></>}
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      height="70vh"
      style={{ overflowY: "scroll" }}
      p={2}
      bgcolor="white"
      borderRadius="8px"
    >
      {isChatLoading ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{ color: "#30325E", width: "18px", height: "18px" }}
          />
        </Box>
      ) : chatMessages.length === 0 ? (
        <Typography style={{ fontFamily: "Cabin" }}>
          No Messages
        </Typography>
      ) : (
        chatMessages.map((message, index) => (
          <Box
            key={message._id}
            sx={{
              display: "flex",
              justifyContent:
                message.senderId === (decodedUser.role == "Admin" ? decodedUser.id : decodedUser.createdBy)
                  ? "flex-end"
                  : "flex-start",
              marginBottom: "15px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                maxWidth: "60%",
                padding: "10px",
                borderRadius: "15px",
                backgroundColor:
                  message.senderId === (decodedUser.role == "Admin" ? decodedUser.id : decodedUser.createdBy)
                    ? "#DCF8C6"
                    : "#FFF",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                position: "relative",
                marginRight:
                  message.senderId === (decodedUser.role == "Admin" ? decodedUser.id : decodedUser.createdBy)
                    ? "10px"
                    : "0px",
                marginLeft:
                  message.senderId !== (decodedUser.role == "Admin" ? decodedUser.id : decodedUser.createdBy)
                    ? "10px"
                    : "0px",
              }}
            >
              {message.text.length > 0 ? (
                <Typography
                  sx={{
                    fontFamily: "Cabin",
                    fontSize: "14px",
                    wordWrap: "break-word",
                  }}
                >
                  {message.text}
                </Typography>
              ) : message.mediaUrl.length > 0 && message.mediaType === "image" ? (
                <img
                  src={message.mediaUrl}
                  alt="Sent Image"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginTop: "5px",
                  }}
                />
              ) : (
                <video
                  src={message.mediaUrl}
                  controls
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginTop: "5px",
                  }}
                />
              )}
              <Typography
                variant="caption"
                display="block"
                sx={{
                  fontFamily: "Cabin",
                  fontSize: "10px",
                  color: "gray",
                  textAlign: "right",
                  marginTop: "5px",
                }}
              >
                {message.addedAt}
              </Typography>
              {/* Attach ref to the last message */}
              {index === chatMessages.length - 1 && <div ref={messagesEndRef} />}
            </Box>
          </Box>
        ))
      )}

      {/* Scroll Indicator */}
      {/* This is used to scroll to the bottom */}
      
      {filePreview && (
        <Box mt={2} mb={2} position="relative">
          {selectedFile.type.startsWith("image/") && (
            <img
              src={filePreview}
              alt="preview"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          )}
          {selectedFile.type.startsWith("video/") && (
            <video
              controls
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            >
              <source src={filePreview} type={selectedFile.type} />
            </video>
          )}
          <IconButton
            style={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              setFilePreview(null);
              setSelectedFile(null);
            }}
          >
            <Close />
          </IconButton>
        </Box>
      )}
    </Box>
    <Box
      display="flex"
      alignItems="center"
      mt={2}
      borderTop="1px solid lightgrey"
    >
      <Input
        fullWidth
        onKeyDown={(e)=>{
          if(e.key=="Enter"){
            handleSendMessage()
          }
        }}
        placeholder="Write message"
        onChange={(e) => {
          setText(e.target.value);
        }}
        inputProps={{ style: { fontFamily: "Cabin" } }}
        style={{ borderBottom: "2px solid #30325E", fontFamily: "Cabin" }}
      />
      <input
        type="file"
        accept="image/*,video/*"
        style={{ display: "none" }}
        id="file-input"
        onChange={handleFileChange}
      />
      <label htmlFor="file-input">
        <IconButton style={{ color: "#30325E" }} component="span">
          <AttachFile />
        </IconButton>
      </label>
      <IconButton
      disabled={isSendingMessage}
        style={{ color: "#30325E" }}
        onClick={handleSendMessage}
      >
      {isSendingMessage?<CircularProgress style={{height:"25px",width:"25px",color:"gray"}} thickness={11} />:<Send/>}
      </IconButton>
    </Box>
  </Paper>
      ) :<></> 
      }

     
      <Modal
        open={isUsersModelOpen}
        onClose={handleCloseUsersModal}
        aria-labelledby="new-group-modal"
        aria-describedby="new-group-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxHeight: "80vh", // Ensures the modal doesn't take up more than 80% of the screen height
            overflowY: "auto", // Makes the modal scrollable if content overflows
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            variant="h6"
            mb={2}
            style={{ fontFamily: "Cabin" }}
            align="center"
          >
            SELECT A USER TO CHAT
          </Typography>

          <Typography
            variant="subtitle1"
            mt={2}
            style={{ fontFamily: "Cabin" }}
            mb={1}
          >
            DRIVERS
          </Typography>
          {allDrivers.length === 0 ? (
            <Typography style={{ fontFamily: "Cabin" }}>
              No Drivers Available!
            </Typography>
          ) : (
            allDrivers.map((driver) => (
              <Card
                onClick={() => {
                  setIsOneToOneChat(true);
                  setIsGroupChat(false);
                  setSelectedReceiverId(driver._id);
                  getChatMessages(driver._id);
                  setReceiverName(driver.firstName + " " + driver.lastName);
                  setIsUsersModelOpen(false);
                  setAnchorEl(null);
                }}
                key={driver.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2, // Adds space between cards
                  p: 1,
                  boxShadow: 3,
                  borderRadius: "8px",
                }}
              >
                <Avatar sx={{ bgcolor: "#30325E", mr: 2, fontFamily: "Cabin" }}>
                  {driver.firstName[0]}
                </Avatar>
                <Typography
                  variant="body1"
                  sx={{ flexGrow: 1, fontFamily: "Cabin" }}
                >
                  {driver.firstName + " " + driver.lastName}
                </Typography>
              </Card>
            ))
          )}

          <Typography
            variant="subtitle1"
            mt={2}
            style={{ fontFamily: "Cabin" }}
            mb={1}
          >
            PATIENTS
          </Typography>
          {allPatients.length === 0 ? (
            <Typography style={{ fontFamily: "Cabin" }}>
              No Patients Available!
            </Typography>
          ) : (
            allPatients.map((patient) => (
              <Card
                onClick={() => {
                  setIsOneToOneChat(true);
                  setIsGroupChat(false);
                  setSelectedReceiverId(patient._id);
                  getChatMessages(patient._id);
                  setReceiverName(patient.firstName + " " + patient.lastName);
                  setIsUsersModelOpen(false);
                  setAnchorEl(null);
                }}
                key={patient.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2, // Adds space between cards
                  p: 1,
                  boxShadow: 3,
                  borderRadius: "8px",
                }}
              >
                <Avatar sx={{ bgcolor: "#30325E", mr: 2 }}>
                  {patient.firstName[0]}
                </Avatar>
                <Typography
                  variant="body1"
                  sx={{ flexGrow: 1, fontFamily: "Cabin" }}
                >
                  {patient.firstName + " " + patient.lastName}
                </Typography>
              </Card>
            ))
          )}

          <Typography
            variant="subtitle1"
            mt={2}
            style={{ fontFamily: "Cabin" }}
            mb={1}
          >
            SUPER ADMIN
          </Typography>
          {superAdmins.length == 0 || decodedUser.role == "User" ? (
            <Typography style={{ fontFamily: "Cabin" }}>
              No Super Admin Found.You May Not Be Allowed To Chat With
              SuperAdmin You May Be A Staff!
            </Typography>
          ) : (
            superAdmins.map((superAdmin) => {
              return (
                <Card
                  onClick={() => {
                    setIsOneToOneChat(true);
                    setIsGroupChat(false);
                    setSelectedReceiverId(superAdmin._id);
                    getChatMessages(superAdmin._id);
                    setReceiverName(superAdmin.firstName+" "+superAdmin.lastName=="Wahab Mazhar"?"Technical Support":"HELP");
                    setIsUsersModelOpen(false);
                    setAnchorEl(null);
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2, // Adds space between cards
                    p: 1,
                    boxShadow: 3,
                    borderRadius: "8px",
                  }}
                >
                  <Avatar sx={{ bgcolor: "#30325E", mr: 2 }}>
                    {superAdmin.firstName[0]}
                  </Avatar>
                  <Typography
                    variant="body1"
                    sx={{ flexGrow: 1, fontFamily: "Cabin" }}
                  >
                    {superAdmin.firstName + " " + superAdmin.lastName}
                  </Typography>
                </Card>
              );
            })
          )}
        </Box>
      </Modal>

      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminChat;
