import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTripContext } from "../../../../Contexts/TripContext";
import { usePatientContext } from "../../../../Contexts/PatientContext";

const DriverSideBar = (props) => {
  const { allTrips } = useTripContext();
  const { allPatients } = usePatientContext();
  const [allTimePatients, setAllTimePatients] = useState([]);
  const [allTimeTrips, setAllTimeTrips] = useState([]);
  const navigate = useNavigate();
  const [noOfTotalPatients, setNofOfTotalPatients] = useState(0);
  const [noOfNewPatients, setNoOfNewPatients] = useState(0);
  const [noOfRepeatedPatients, setNoOfRepeatedPatients] = useState(0);
  const [noOfPatientsWithCancelledRides, setNofOfPatientsWithCancelledRides] = useState(0);
  const [noOfPatientsWithBookedRides, setNofOfPatientsWithBookedRides] = useState(0);
  const [todaysBookings, setTodaysBooking] = useState([]);
  const [todaysCancellations, setTodaysCancellations] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch and process data
  let getPatientsAndTrips = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      let currentDate = new Date().toLocaleDateString();
      currentDate = currentDate.split("/");
      currentDate[0] = Number(currentDate[0]) < 10 ? "0" + currentDate[0] : currentDate[0];
      currentDate = currentDate[2] + "-" + currentDate[0] + "-" + currentDate[1];

      let REPEATEDPATIENTS = [];
      let NEWPATIENTS = [];
      let PATIENTSWITHCANCELLEDRIDES = [];
      let PATIENTSWITHBOOKEDRIDES = [];

      for (let patient of allTimePatients) {
        let bookedRides = allTimeTrips.filter((trip) => trip.patientRef == patient._id);
        let cancelledRides = allTimeTrips.filter((trip) => trip.patientRef == patient._id && trip.status == "Cancelled");

        if (bookedRides.length > 0) {
          REPEATEDPATIENTS.push(patient);
          PATIENTSWITHBOOKEDRIDES.push(patient);
        }
        if (bookedRides.length == 1) {
          NEWPATIENTS.push(patient);
        }
        if (cancelledRides.length > 0) {
          PATIENTSWITHCANCELLEDRIDES.push(patient);
        }
      }

      setNofOfTotalPatients(allTimePatients.length);
      setNoOfRepeatedPatients(REPEATEDPATIENTS.length);
      setNoOfNewPatients(NEWPATIENTS.length);
      setNofOfPatientsWithBookedRides(PATIENTSWITHBOOKEDRIDES.length);
      setNofOfPatientsWithCancelledRides(PATIENTSWITHCANCELLEDRIDES.length);

      setTodaysBooking(
        allTimeTrips.filter((trip) => trip.status != "Cancelled" && trip.createdAt.split("T")[0] == currentDate)
      );
      setTodaysCancellations(
        allTimeTrips.filter((trip) => trip.status == "Cancelled" && trip.createdAt.split("T")[0] == currentDate)
      );
    } catch (e) {
      toast.error(e.message, { style: { fontFamily: "Cabin", fontWeight: "bold" } });
    } finally {
      setLoading(false);
    }
  };

  // Update allTimePatients and allTimeTrips when allPatients or allTrips change
  useEffect(() => {
    if (allPatients) {
      setAllTimePatients(allPatients);
    }
    if (allTrips) {
      setAllTimeTrips(allTrips);
    }
  }, [allPatients, allTrips]);

  // Fetch data when allTimePatients or allTimeTrips change
  useEffect(() => {
    if (allTimePatients.length > 0 && allTimeTrips.length > 0) {
      getPatientsAndTrips();
    }
  }, [allTimePatients, allTimeTrips]);

  // Check token validity and fetch data
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds

        if (decodedToken.exp && decodedToken.exp > currentTime) {
          getPatientsAndTrips();
        } else {
          console.log("Token has expired");
          navigate("/admin/login");
        }
      } catch (error) {
        console.log("Invalid token", error);
        navigate("/admin/login");
      }
    } else {
      navigate("/admin/login");
    }
  }, [allPatients, allTrips]);

  if (loading) {
    return (
      <div className="h-screen laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white">
        <div className="flex justify-center items-center h-[700px]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-deepBlue"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-screen laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white min-h-screen">
        <div className="w-[100%]">
          <div className="flex float-right text-lap_e cursor-pointer"></div>
          <h2 className="text-lap_c mt-[60px] font-[600]">Total Patients</h2>
          <h2 className="text-lap_d font-[700] text-deepBlue">{noOfTotalPatients}</h2>
          <div className="grid items-center my-[20px] gap-[10%]">
            <div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#409261] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp; <span className="text-deepBlue text-lap_b">{noOfNewPatients}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">New Patients</span>
              </div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#D98634] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp; <span className="text-deepBlue text-lap_b">{noOfRepeatedPatients}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">Repeated Patients</span>
              </div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#E42346] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp; <span className="text-deepBlue text-lap_b">{noOfPatientsWithBookedRides}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">Patients Booked Trips</span>
              </div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#409261] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp; <span className="text-deepBlue text-lap_b">{noOfPatientsWithCancelledRides}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">Patients cancelled Trips</span>
              </div>
            </div>
            
            <div className="mt-[10px]">
              <h2 className="text-lap_c font-[600] my-[5px]">Today’s Booking</h2>
              <h1 className="text-lap_d font-[800] text-[#30325E] my-[5px]">{todaysBookings.length}</h1>
            </div>
            <div className="mb-[10px]">
              <h2 className="text-lap_c font-[600] mb-[5px]">Today’s Cancellation</h2>
              <h1 className="text-lap_d font-[800] text-[#30325E] my-[5px]">{todaysCancellations.length}</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default DriverSideBar;