import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast,ToastContainer } from "react-toastify";

// import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import PatientprofileHeader from "./PatientprofileHeader";
import SinglePatientSideBar from "./SinglePatientSideBar";
import Modal from "../../../Modal";
import Rides from "./Rides";
import axios from "axios";
import { Card, CircularProgress } from "@mui/material";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { FaCarSide } from "react-icons/fa6";
import { MdLinkedCamera } from "react-icons/md";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { jwtDecode } from "jwt-decode";
import { useTripContext } from "../../../../Contexts/TripContext";
import { usePatientContext } from "../../../../Contexts/PatientContext";
const position = [51.505, -0.09];


const EditPatient = () => {
  const{allTrips}=useTripContext()
  const{setAllPatients}=usePatientContext()
  const[allTimeTrips,setAllTimeTrips]=useState([])
  const[noOfTotalTrips,setNoOfTotalTrips]=useState(0)
  const[noOfOnGoingTrips,setNoOfOnGoingTrips]=useState(0)
  const[noOfCompletedTrips,setNoOfCompletedTrips]=useState(0)
  const[noOfCancelledTrips,setNofOfCancelledTrips]=useState(0)
  const[last24HoursRidden,setLast24HoursRidden]=useState(0)
  const[last7DaysRidden,setLast7DaysRidden]=useState(0)
  const[last30DaysRidden,setLast30DaysRidden]=useState(0)
  const[allTimeRidden,setAllTimeRidden]=useState(0)
  const now=new Date()
  const[profilePhoto,setProfilePhoto]=useState(null)
  const[signature,setSignature]=useState(null)
  const[profilePhotoUrl,setProfilePhotoUrl]=useState("")
  const[signatureUrl,setSignatureUrl]=useState("")
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePhoto(file); // Set the file to the state
      setProfilePhotoUrl(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days in milliseconds
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  let navigate=useNavigate()
 const[patient,setPatient]=useState({})
const{Id}=useParams()
const[firstName,setFirstName]=useState("")
   const[lastName,setLastName]=useState("")
   const[EMailAddress,setEMailAddress]=useState("")
   const[phoneNumber,setPhoneNumber]=useState("")
   const[location,setLocation]=useState("")
   const[age,setAge]=useState()
   
   const[gender,setGender]=useState("")
   const[companyCode,setCompanyCode]=useState("RWL2000")
   const[loading,setLoading]=useState(false)
let getPatientById=async()=>{
  try{
  const token=localStorage.getItem("token")
  const response=await axios.get(`https://api.triphog.net/api/v1/patient/getone/${Id}`,{headers:{'Authorization':token}})
  
  let ONGOING=allTimeTrips.filter((trip)=>{
    return(trip.status=="On Route" && trip.patientRef==Id)
  })
  let COMPLETED=allTimeTrips.filter((trip)=>{
    return(trip.status=="Completed" && trip.patientRef==Id)
  })
  let CANCELLED=allTimeTrips.filter((trip)=>{
    return(trip.status=="Cancelled" && trip.patientRef==Id)
  })
  let TOTAL=allTimeTrips.filter((trip)=>{
    return(trip.patientRef==Id)
  })
  console.log(ONGOING.length)
  console.log(CANCELLED)
  console.log(COMPLETED.length)
  ONGOING=ONGOING.length
  CANCELLED=CANCELLED.length
  COMPLETED=COMPLETED.length
  TOTAL=TOTAL.length
  let MyTrips=allTimeTrips.filter((trip)=>{
    return(trip.patientRef==Id)
  })
  console.log("My Trips Of Driver With Id",Id,"Are",MyTrips)
  let mycompletedRides=MyTrips.filter((trip)=>{
    return(trip.status=="Completed")
  })
  let last24HoursCompletedRides=mycompletedRides.filter((trip)=>{
    return(new Date(trip.createdAt)>=twentyFourHoursAgo)
  })
  let last7DaysCompletedRides=mycompletedRides.filter((trip)=>{
    return(new Date(trip.createdAt)>=sevenDaysAgo)
  })
  let last30DaysCompletedRides=mycompletedRides.filter((trip)=>{
    return(new Date(trip.createdAt)>=thirtyDaysAgo)
  })
  if(mycompletedRides.length>0)
  {
    let hoursRidden=0
    for(let trip of mycompletedRides)
    {
      hoursRidden=hoursRidden+trip.timeTaken
    }
    setAllTimeRidden(hoursRidden)
  }
  if(last24HoursCompletedRides.length>0)
  {
    let hoursRidden=0
    for(let trip of last24HoursCompletedRides)
    {
      hoursRidden=hoursRidden+trip.timeTaken
    }
    setLast24HoursRidden(hoursRidden)

  }
  if(last7DaysCompletedRides.length>0)
  {
    let hoursRidden=0
    for(let trip of last7DaysCompletedRides)
    {
      hoursRidden=hoursRidden+trip.timeTaken
    }
    setLast7DaysRidden(hoursRidden)
  }
  if(last30DaysCompletedRides.length>0)
  {
    let hoursRidden=0
    for(let trip of last30DaysCompletedRides)
    {
      hoursRidden=hoursRidden+trip.timeTaken
    }
    setLast30DaysRidden(hoursRidden)

  }
  console.log("ON Going",ONGOING)
  setNoOfOnGoingTrips(ONGOING)
  setNofOfCancelledTrips(CANCELLED)
  setNoOfCompletedTrips(COMPLETED)
  setNoOfTotalTrips(TOTAL)

  console.log(response.data)
  setPatient(response.data.patient)
  setFirstName(response.data.patient.firstName)
  setLastName(response.data.patient.lastName)
  setEMailAddress(response.data.patient.EMailAddress)
  setPhoneNumber(response.data.patient.phoneNumber)
  setLocation(response.data.patient.location)
  setAge(response.data.patient.age)
  setGender(response.data.patient.gender)
  setProfilePhotoUrl(response.data.patient.profilePhotoUrl)
  setSignatureUrl(response.data.patient.signatureUrl)
  setCompanyCode(response.data.patient.companyCode)
}
catch(e){
  toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bold"}})
}
}
let updatePatient=async()=>{
    setLoading(true)
    const token=localStorage.getItem("token")
    try{
      const formData=new FormData()
      formData.append("firstName",firstName)
      formData.append("lastName",lastName)
      formData.append("EMailAddress",EMailAddress)
      formData.append("location",location)
      formData.append("phoneNumber",phoneNumber)
      formData.append("gender",gender)
      formData.append("age",age)
      formData.append("profilePhoto",profilePhoto)
      formData.append("signature",signature)
  let response=await axios.put(`https://api.triphog.net/api/v1/patient/update/${Id}`,formData,{headers:{'Authorization':token,"Content-Type":"multipart/form-data"}})
  console.log(response.data)
  if(response.data.success)
  {
    toast.success("Successfully Updated!",{position:"top-center"})
    setAllPatients((prevPatients) =>
      prevPatients.map((patient) =>
        patient._id === response.data.updatedPatient._id ? response.data.updatedPatient : patient
      )
    );
  setLoading(false)

  }
  else

  {
    toast.error("Error While Updating!",{position:"top-center"})
  setLoading(false)

  }
}
catch(e){
  setLoading(false)
  toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bold"}})
}
}
useEffect(()=>{
  if(allTrips){
    setAllTimeTrips(allTrips)
  }
},[allTrips])
useEffect(()=>{
  const token = localStorage.getItem("token");
     
    
     
     if (token) {
       try {
         const decoded = jwtDecode(token);
     
         // Check if the token is expired
         const currentTime = Date.now() / 1000; // Convert to seconds
         if (decoded.exp && decoded.exp > currentTime) {
  getPatientById()
          
         } else {
           console.log("Token has expired");
           navigate("/admin/login")
         }
       } catch (error) {
         navigate("/admin/login")
         console.log("Invalid token", error);
       }
     }
     
   else {
       navigate("/admin/login")
       console.log("No valid token available, user not decoded.");
     }
},[])
const[decodedUser,setDecodedUser]=useState({});

useEffect(()=>{
  const token = localStorage.getItem("token");
     
   
   if (token) {
     try {
       const decoded = jwtDecode(token);
   
       // Check if the token is expired
       const currentTime = Date.now() / 1000; // Convert to seconds
       if (decoded.exp && decoded.exp > currentTime) {
         setDecodedUser(decoded)
       } else {
         console.log("Token has expired");
         navigate("/admin/login")
       }
     } catch (error) {
       navigate("/admin/login")
       console.log("Invalid token", error);
     }
   }
   
   if (decodedUser) {
     console.log("Decoded User:", decodedUser);
   } else {
     navigate("/admin/login")
     console.log("No valid token available, user not decoded.");
   }
},[navigate])


  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  if(decodedUser.role=="User" &&(!checkPermission("Access To Edit Patient")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  else{
if(Object.keys(patient).length==0)
{
  return ( <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <CircularProgress size={60} thickness={5} color="inherit" />
  </div>)
}
else
{
  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
      <button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",marginTop:"1px"}}>BACK</h5>
          
          </button>
        
        
        <div className="w-full h-auto  flex  flex-col items-center">
  <div className="relative w-[150px] h-[150px] mb-2"> {/* Reduced margin bottom */}
    <img
      src={profilePhotoUrl || 'https://via.placeholder.com/150'}
      alt="Profile"
      className="w-full h-full object-cover rounded-full"
    />
    <label htmlFor="upload_profile" className="absolute bottom-0 right-0 bg-white rounded-full p-2 cursor-pointer">
      <input
        type="file"
        id="upload_profile"
        hidden
        accept="image/*"
        onChange={handlePhotoChange}
      />
      <span className="text-[25px] text-deepBlue">
        <MdLinkedCamera />
      </span>
    </label>
  </div>
  </div>
        
        <div>
          <form
            className="text-darkgray laptop:text-lap_b"
            // onSubmit={handleSubmit}
          >
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">First Name</label>
                <br />
                <input
                
                value={firstName}
                  type="text"
                  // placeholder={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                
                value={lastName}
                  type="text"
                  // placeholder={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Your Email</label>
                <br />
                <input
            disabled
                type="text"
                  value={EMailAddress}
                  // placeholder={email}
                  onChange={(event) => {
                    setEMailAddress(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                
                value={phoneNumber}
                  type="text"
                  // placeholder={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Location</label>
                <br />
                <input
                
                value={location}
                  type="text"
                  
                  onChange={(event) => {
                    setLocation(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Age</label>
                <br />
                <input
                
                  type="number"
                 value={age}
                  onChange={(event) => {
                    setAge(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Gender</label>
                <br />
                <select
                
                value={gender}
                   onChange={(event) => {                  
                     setGender(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option selected>Select Gender</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                </select>
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
              <label>Signature</label>
             {signatureUrl.length>0? <img
               src={signatureUrl}
                alt="Signature"
                className="h-[120px] w-[120px]"
              />:<></>}
               <input
                  type="file"
                  
                  onChange={(event) => {
                    setSignature(event.target.files[0])
                    setSignatureUrl(URL.createObjectURL(event.target.files[0]))
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
            </div>
            </div>
           
                        {/* Submit Button */}
              <div className="flex justify-center items-center mt-8">
                {loading ? (
                  <button className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-full py-[12px] px-[50px] flex items-center justify-center">
                    <CircularProgress style={{ color: "white", height: "20px", width: "20px" }} thickness={8} />
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      updatePatient();
                    }}
                    type="submit"
                    className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-full py-[12px] px-[50px] transition-all"
                  >
                    Update
                  </button>
                )}
              </div>
          </form>
        </div>
        {/* <Rides /> */}
      </div>
   
      <div className="bg-white laptop:mt-[-80px]">
      <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white">
      <div className="w-[100%]">
        {/* <div className="flex float-right text-lap_e cursor-pointer">
          <IoNotificationsOutline />
        </div> */}
        <h2 className="text-lap_c mt-[60px] font-[600]">Total Trips</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{noOfTotalTrips}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfOnGoingTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Ongoing</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#D98634] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfCompletedTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Completed</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfCancelledTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Cancelled</span>
            </div>
          </div>
          
        </div>
      </div>
      <div>
        <h1 className="text-lap_c font-[600] my-[20px]">Hours Ridden</h1>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 24 hours</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last24HoursRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 7 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last7DaysRidden.toFixed(2)}</h2>
          </div>
        </div>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 30 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last30DaysRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">All Time</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{allTimeRidden.toFixed(2)}</h2>
          </div>
        </div>
      </div>
      <div>
        {/* <h2 className="text-lap_c font-[800] my-[10px]">Live Tracking</h2>
        <div>
          <MapContainer
            center={position}
            zoom={13}
            scrollWheelZoom={true}
            style={{ height: "300px" }}
            className="rounded-lg border"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>Driver</Popup>
            </Marker>
          </MapContainer>
        </div> */}
      </div>
    </div>
      </div>
      <>
        {/* {status && (
          <Modal status={status} setStatus={setStatus} message={modalMessage} />
        )} */}
      </>
    </>
  );
}}
};

export default EditPatient;

























// import React, { useEffect, useState } from "react";

// // import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
// import PatientprofileHeader from "./PatientprofileHeader";
// import SinglePatientSideBar from "./SinglePatientSideBar";
// import Modal from "../../../Modal";
// import Rides from "./Rides";
// import axios from "axios";
// import { toast,ToastContainer } from "react-toastify";
// import { useParams } from "react-router-dom";
// import { CircularProgress } from "@mui/material";
// const EditPatient = () => {
//     const{Id}=useParams()
//   const[firstName,setFirstName]=useState("")
//   const[lastName,setLastName]=useState("")
//   const[EMailAddress,setEMailAddress]=useState("")
//   const[phoneNumber,setPhoneNumber]=useState("")
//   const[location,setLocation]=useState("")
//   const[age,setAge]=useState()
//   const[gender,setGender]=useState("")
//   const[companyCode,setComanyCode]=useState("RWL2000")
//   const[loading,setLoading]=useState(false)
//   let getPatientById=async()=>{
//     let response=await axios.get(`https://api.triphog.net/api/v1/patient/getone/${Id}`)
//     setFirstName(response.data.patient.firstName)
//     setLastName(response.data.patient.lastName)
//     setEMailAddress(response.data.patient.EMailAddress)
//     setPhoneNumber(response.data.patient.phoneNumber)
//     setLocation(response.data.patient.location)
//     setAge(response.data.patient.age)
//     setGender(response.data.patient.gender)
//     setComanyCode(response.data.patient.companyCode)
  
//   }
//   useEffect(()=>{
//     getPatientById()
//   },[])
// let updatePatient=async()=>{
//     setLoading(true)
//   let response=await axios.put(`https://api.triphog.net/api/v1/patient/update/${Id}`,{firstName,lastName,EMailAddress,phoneNumber,location,gender,companyCode,age})
//   console.log(response.data)
//   if(response.data.success)
//   {
//     toast.success("Successfully Updated!",{position:"top-center"})
//   setLoading(false)

//   }
//   else

//   {
//     toast.error("Error While Updating!",{position:"top-center"})
//   setLoading(false)

//   }
// }

//   return (
//     <>
//       <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
//         <ToastContainer/>
      
//         <div>
//           <form
//             className="text-darkgray laptop:text-lap_b"
//             // onSubmit={handleSubmit}
//           >
//             <div className="laptop:flex gap-16 laptop:my-[16px]">
//               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
//                 <label className="">First Name</label>
//                 <br />
//                 <input
//                   type="text"
//                   value={firstName}
//                   onChange={(event) => {
//                     setFirstName(event.target.value);
//                   }}
//                   className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
//                 />
//               </div>
//               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
//                 <label>Last Name</label>
//                 <br />
//                 <input
//                   type="text"
//                   value={lastName}
//                   onChange={(event) => {
//                     setLastName(event.target.value);
//                   }}
//                   className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
//                 />
//               </div>
//             </div>
//             <div className="laptop:flex gap-16 laptop:my-[16px]">
//               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
//                 <label className="">Your Email</label>
//                 <br />
//                 <input
//                   type="text"
//                   value={EMailAddress}
//                   onChange={(event) => {
//                  setEMailAddress(event.target.value);
//                   }}
//                   className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
//                 />
//               </div>
//               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
//                 <label>Phone Number</label>
//                 <br />
//                 <input
//                   type="text"
//                   value={phoneNumber}
//                   onChange={(event) => {
//                     setPhoneNumber(event.target.value);
//                   }}
//                   className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
//                 />
//               </div>
//             </div>
//             <div className="laptop:flex gap-16 laptop:my-[16px]">
//               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
//                 <label className="">Location</label>
//                 <br />
//                 <input
//                   type="text"
//                  value={location}
//                   onChange={(event) => {
//                     setLocation(event.target.value);
//                   }}
//                   className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
//                 />
//               </div>
//               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
//                 <label>Company Code</label>
//                 <br />
//                 <input
//                   type="text"
//                   placeholder={companyCode}
//                   disabled

//                   onChange={(event) => {
//                     setComanyCode(event.target.value);
//                   }}
//                   className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
//                 />
//               </div>
//             </div>
//             <div className="laptop:flex gap-16 laptop:my-[16px]">
//               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
//                 <label className="">Age</label>
//                 <br />
//                 <input
//                   type="number"
//                 value={age}
//                   onChange={(event) => {
//                     setAge(event.target.value);
//                   }}
//                   className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
//                 />
//               </div>
//               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
//                 <label>Gender</label>
//                 <br />
//                 <select
//                 value={gender}
//                    onChange={(event) => {                  
//                      setGender(event.target.value);
//                    }}
//                   className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
//                 >
//                   <option selected>Select Gender</option>
//                   <option value={"male"}>Male</option>
//                   <option value={"female"}>Female</option>
//                 </select>
//               </div>
//             </div>

            
          
//             <div className="laptop:flex laptop:justify-center laptop:items-center">
//            {loading?<CircularProgress/>: <button                 className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]" onClick={(e)=>{
//                     e.preventDefault()
                    
//                     updatePatient()
//                 }} >
//                     Update
//                 </button>}    
              
//             </div>
//           </form>
//         </div>
    
//       </div>

//       <div className="bg-white laptop:mt-[-80px]">
      
//       </div>
//       <>
//         {/* {status && (
//           <Modal status={status} setStatus={setStatus} message={modalMessage} />
//         )} */}
//       </>
//     </>
//   );
// };

// export default EditPatient;
