import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Avatar, Typography, Input, Snackbar,Alert,IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Card, CardContent, Modal, Button, TextField, Checkbox, FormControlLabel, Menu, MenuItem, Chip, CircularProgress } from '@mui/material';
import { Send, AttachFile, Add, Close, ChatOutlined } from '@mui/icons-material';

import axios from 'axios';
import { io } from 'socket.io-client';
import { useSocket } from '../../SocketContext/SocketContext';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { useChatReceiverId } from '../SuperAdminChatReceiverContext/SuperAdminChatReceiverContext';
import { useNavigate } from 'react-router-dom';
const Chat = () => {
    const { receiverId, updateChatReceiverId } = useChatReceiverId();
    const socket = useSocket();
    const navigate = useNavigate();
    const[isSendingMessage,setIsSendingMessage]=useState(false)
    const messagesEndRef = useRef(null);
  
    // State declarations
    const [isChatsLoading, setIsChatsLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [decodedUser, setDecodedUser] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [chats, setChats] = useState([]);
    const [allChats, setAllChats] = useState([]);
    const [text, setText] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [receiverName, setReceiverName] = useState("");
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [isUsersModelOpen, setIsUsersModelOpen] = useState(false);
    const [isOneToOneChat, setIsOneToOneChat] = useState(false);
    const [selectedReceiverId, setSelectedReceiverId] = useState(null);
  
    // Token validation effect
    useEffect(() => {
      const validateToken = () => {
        const token = localStorage.getItem("superadmintoken");
        if (!token) {
          navigate("/superadmin/login");
          return false;
        }
  
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
  
          if (decodedToken.exp && decodedToken.exp > currentTime) {
            setDecodedUser(decodedToken);
            return true;
          } else {
            console.log("Token has expired");
            navigate("/superadmin/login");
            return false;
          }
        } catch (error) {
          console.log("Invalid token", error);
          navigate("/superadmin/login");
          return false;
        }
      };
  
      const isValid = validateToken();
      if (isValid) {
        getMyChats();
        getAllAdmins();
      }
    }, []); // Empty dependency array as this should only run once on mount
  
    // Reset chat receiver effect
    useEffect(() => {
      updateChatReceiverId(null);
    }, [updateChatReceiverId]);
  
    // Scroll to bottom effect
    useEffect(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, [chatMessages]);
  
    // Socket connection effect
    useEffect(() => {
      if (!socket) return;
  
      const handleNewMessage = (message) => {
        console.log("RECEIVER ID Of Message",selectedReceiverId)
        console.log("handling New Message",message)
        if (selectedReceiverId && message.senderId === selectedReceiverId) {
          getChatMessages(message.senderId);
        }
        getMyChats();
      };
  
      socket.on("newMsg", handleNewMessage);
      socket.on("disconnect", () => {
        console.log("Super Admin Has Been Disconnected From Socket Server");
      });
  
      return () => {
        socket.off("newMsg", handleNewMessage);
        socket.off("disconnect");
      };
    }, [socket, receiverId]);
  
    const getChatMessages = async (ReceiverId) => {
      try {
        setIsChatLoading(true);
        const response = await axios.get(`https://api.triphog.net/api/v1/chat/getmessages/${localStorage.getItem("superAdminId")}/${ReceiverId}`);
        setChatMessages(response.data.messages);
        setIsChatLoading(false);
      } catch (e) {
        toast.error("Could Not Fetch Chat Messages!");
        setIsChatLoading(false);
      }
    };
  
    const getMyChats = async () => {
      try {
        setIsChatsLoading(true);
        const response = await axios.get("https://api.triphog.net/api/v1/chat/getmychats", {
          headers: { "Authorization": localStorage.getItem("superadmintoken") }
        });
        
        const sortedChats = response.data.chats.sort((a, b) => {
          const dateA = new Date(a.lastMessage.addedON);
          const dateB = new Date(b.lastMessage.addedON);
          return dateB - dateA;
        });
        
        setChats(sortedChats);
        setAllChats(sortedChats);
        setIsChatsLoading(false);
      } catch (e) {
        toast.error("Error While Getting Chats");
        setIsChatsLoading(false);
      }
    };
  
    const getAllAdmins = async () => {
      try {
        const response = await axios.get("https://api.triphog.net/api/v1/superadmin/admin");
        setAdmins(response.data.data);
      } catch (e) {
        toast.error("Error While Getting Admins");
      }
    };
  
    const handleCloseUsersModal = () => {
      setIsUsersModelOpen(false);
    };
  
    const handleSnackBarClose = (event, reason) => {
      if (reason === 'clickaway') return;
      setIsSnackBarOpen(false);
    };
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  
    const handleSendMessage = async () => {
      setIsSendingMessage(true)
      if (!isOneToOneChat) return;
  
      try {
        const formData = new FormData();
        formData.append("senderName", `${decodedUser.firstName} ${decodedUser.lastName}`);
  
        if (selectedFile) {
          formData.append("file", selectedFile);
          formData.append("mediaType", selectedFile.type.split("/")[0]);
          formData.append("text", text);
        } else if (text.length > 0) {
          formData.append("text", text);
        } else {
          setMessage("Cannot Send Empty Message");
          setSeverity("info");
          setIsSnackBarOpen(true);
          return;
        }
  
        const response = await axios.post(
          `https://api.triphog.net/api/v1/chat/sendmessage/${localStorage.getItem("superAdminId")}/${selectedReceiverId}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
  
        if (response.data.success) {
          setMessage("Successfully Message Sent");
          setSeverity("success");
          getChatMessages(selectedReceiverId);
          getMyChats();
        } else {
          setMessage("Error While Sending Message!");
          setSeverity("error");
        }
  
        setIsSnackBarOpen(true);
        setSelectedFile(null);
        setFilePreview(null);
        setText('');
      } catch (e) {
        toast.error("Error While Sending Message! Please Try Again");
      }
      finally{
        setIsSendingMessage(false)
      }
    };
  
    return (
        <Box display="flex" width="1180px" height="100vh" bgcolor="white" p={2} gap={2}>
            {/* Left Sidebar */}
            <Paper elevation={3} style={{ width: '25%', borderRadius: '8px', padding: '16px' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" style={{ color: "#30325E", fontFamily: "Cabin" }}>
            Inquiries
        </Typography>
        <IconButton style={{ color: "#30325E", cursor: 'pointer' }} onClick={handleClick}>
            <Add />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem style={{ color: "#30325E", fontFamily: "Cabin", cursor: 'pointer' }} onClick={() => {
                setIsUsersModelOpen(true);
            }}>
                New Chat
            </MenuItem>
        </Menu>
    </Box>
    <Input
        fullWidth
        placeholder="Search chats"
        margin="normal"
        inputProps={{ style: { fontFamily: "Cabin", borderBottom: "1px solid #30325E" } }}
        style={{ fontFamily: "Cabin" }}
        onChange={(e) => {
            let searchedChats = allChats.filter((chat) => {
                return (chat.with.includes(e.target.value) || chat.lastMessage.text.includes(e.target.value));
            });
            setChats(searchedChats);
        }}
    />
    {isChatsLoading ? (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
            <CircularProgress style={{ color: "#30325E", height: "18px", width: "18px" }} />
        </div>
    ) : chats.length === 0 ? (
        <Typography style={{ fontFamily: "Cabin", textAlign: "center", marginTop: "20px" }}>
            No Chats Yet!
        </Typography>
    ) : (
        <List style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {chats.map((chat) => (
                <ListItem
                    sx={{
                        backgroundColor: selectedReceiverId === chat.withId && isOneToOneChat === true ? "#30325E" : "white",
                        borderRadius: "5px",
                        color: selectedReceiverId === chat.withId && isOneToOneChat === true ? "white" : "black",
                        cursor: "pointer"
                    }}
                    onClick={async() => {
                        setIsOneToOneChat(true);
                        setSelectedReceiverId(chat.withId);
                        updateChatReceiverId(chat.withId)
                        await getChatMessages(chat.withId);
                        setReceiverName(chat.with);
                    }}
                >
                    <ListItemAvatar>
                        <Avatar sx={{ fontFamily: "Velyra" }}>{chat.with[0]}</Avatar>
                    </ListItemAvatar>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ fontFamily: "Cabin" }}>{chat.with}</Typography>
                        <Typography sx={{ fontFamily: "Cabin" }}>
                            {chat.lastMessage.text.length > 0 ? chat.lastMessage.text : chat.lastMessage.mediaUrl.length > 0 && chat.lastMessage.mediaType === "image" ? "Sent An Image" : "Sent A Video"}
                        </Typography>
                    </Box>
                </ListItem>
            ))}
        </List>
    )}
</Paper>

            {/* Main Chat Area */}
            {isOneToOneChat==false   ?     <Paper elevation={3} style={{ flex: 1, borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'column', overflow: 'hidden',fontFamily:"Cabin" }}>
            TRIPHOG
            </Paper>: <Paper elevation={3} style={{ flex: 1, borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
                <Box mb={2}>
                    <Typography variant="h6" style={{fontFamily:"Cabin"}}>
                        {receiverName}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    height="70vh"
                    style={{overflowY:"scroll"}}
                    p={2}
                    bgcolor="white"
                    borderRadius="8px"
                >
                    {isChatLoading?<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}><CircularProgress style={{color:"#30325E",height:"18px",width:"18px"}}/></div>:chatMessages.length==0?<Typography style={{fontFamily:"Cabin"}}>No Messages</Typography>: chatMessages.map((message,index) => (
                        <Box key={message._id} variant="outlined" style={{ marginBottom: '10px',height:"350px",width:"50%" ,marginLeft:message.senderId==localStorage.getItem("superAdminId")?"auto":"0px",marginRight:"100px"}}>
                            {message.text.length>0 ? <Card sx={{height:"100px",width:"490px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <Typography variant="body1" style={{fontFamily:"Cabin"}}>
                                    {message.text}
                                </Typography>
                                <Typography variant="caption" display="block" sx={{marginLeft:"auto",fontFamily:"Cabin"}}>
                                    {message.addedAt}
                                </Typography>
                            </Card>:message.mediaUrl.length>0 &&message.mediaType=="image"?  <Card  sx={{height:"150px",width:"490px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <img style={{height:"85%",width:"98%",borderRadius:"5px"}} src={message.mediaUrl} />
                                <Typography variant="caption" display="block" sx={{marginLeft:"auto",fontFamily:"Cabin"}}>
                                    {message.addedAt}
                                </Typography>
                            </Card>:  <Card  sx={{height:"150px",width:"490px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                <video style={{height:"85%",width:"98%",borderRadius:"5px"}} src={message.mediaUrl} controls={true}></video>                          
                                <Typography variant="caption" display="block" sx={{marginLeft:"auto",fontFamily:"Cabin"}}>
                                    {message.addedAt}
                                </Typography>
                            </Card>}
              {index === chatMessages.length - 1 && <div ref={messagesEndRef} />}
                          
                        </Box>
                    ))}

                    {filePreview && (
                        <Box mt={2} mb={2} position="relative">
                            {selectedFile.type.startsWith('image/') && (
                                <img src={filePreview} alt="preview" style={{ maxWidth: '100%', borderRadius: '8px' }} />
                            )}
                            {selectedFile.type.startsWith('video/') && (
                                <video controls style={{ maxWidth: '100%', borderRadius: '8px' }}>
                                    <source src={filePreview} type={selectedFile.type} />
                                </video>
                            )}
                            <IconButton
                                style={{ position: 'absolute', top: 0, right: 0 }}
                                onClick={() => {
                                    setFilePreview(null);
                                    setSelectedFile(null);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <Box display="flex" alignItems="center" mt={2} borderTop="1px solid lightgrey">
                    <Input
                    onKeyDown={(e)=>{
                      if(e.key=="Enter"){
                        handleSendMessage()
                      }
                    }}
                        fullWidth
                        placeholder="Write message"
                        
                        onChange={(e)=>{
                            setText(e.target.value)
                        }}
                        inputProps={{style:{fontFamily:"Cabin"}}}
                        style={{ borderBottom: '1px solid #30325E' ,fontFamily:"Cabin"}}
                    />
                    <input
                    
                        type="file"
                        accept="image/*,video/*"
                        style={{ display: 'none' }}
                        id="file-input"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="file-input">
                        <IconButton style={{color:"#30325E"}} component="span">
                            <AttachFile />
                        </IconButton>
                    </label>
                    <IconButton disabled={isSendingMessage} style={{color:"#30325E"}} onClick={handleSendMessage}>
                      {isSendingMessage?<CircularProgress style={{height:"25px",width:"25px",color:"gray"}} thickness={11}  />:<Send/>}
                    </IconButton>
                </Box>
            </Paper>}


            {/* New Group Modal */}
          
        <Modal
    open={isUsersModelOpen}
    onClose={handleCloseUsersModal}
    aria-labelledby="new-group-modal"
    aria-describedby="new-group-modal-description"
>
    <Box
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            maxHeight: '80vh', // Ensures the modal doesn't take up more than 80% of the screen height
            overflowY: 'auto', // Makes the modal scrollable if content overflows
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4
        }}
    >
        

        <Typography variant="subtitle1" mt={2} mb={1} style={{fontFamily:"Cabin"}} >ADMINS</Typography>
       {admins.length === 0 ? (
            <Typography style={{fontFamily:"Cabin"}}>No Admins Available!</Typography>
        ) : (
            admins.map((admin) => (
                <Card 
                onClick={()=>{
                    setIsOneToOneChat(true);
                  
                     setSelectedReceiverId(admin._id);
                      getChatMessages(admin._id);
                      setReceiverName(admin.firstName+" "+admin.lastName)
                      setIsUsersModelOpen(false)
                      setAnchorEl(null)
                    
                }}
                    key={admin.id}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2, // Adds space between cards
                        p: 1,
                        boxShadow: 3,
                        borderRadius: '8px'
                    }}
                >
                    <Avatar sx={{ bgcolor: '#30325E', mr: 2,fontFamily:"Cabin" }}>
                        {admin.firstName[0]}
                    </Avatar>
                    <Typography variant="body1" sx={{ flexGrow: 1,fontFamily:"Cabin" }}>
                        {admin.firstName + " " + admin.lastName}
                    </Typography>
                </Card>
            ))
        )}

      
    </Box>
</Modal>

        <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackBarClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
);
};

export default Chat;

