import React, { useRef } from "react";
import { CiMenuKebab, CiSearch } from "react-icons/ci";
import { MdOutlineCloudDownload, MdOutlineFileUpload } from "react-icons/md";
import RideData from "./RideData";
import { FaPencil } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RideSideBar from "./RideSideBar";
// import { useAllAdminsContext } from "../../../Providers/SuperAdmin/AllAdminsProvider";
import { Link, useNavigate } from "react-router-dom";
import {
  Menu,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  InputAdornment,
  Divider,
  IconButton,
  CircularProgress,
  setRef,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import jsPDF from "jspdf";
import "jspdf-autotable";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file

// import { useDeleteAdminContext } from "../../../Providers/SuperAdmin/DeleteAdminProvider";
import Modal from "../../../Modal";
import { useEffect, useState } from "react";
import axios, { all } from "axios";
import SearchIcon from "@mui/icons-material/Search";
import CircleIcon from "@mui/icons-material/Circle";
import html2canvas from "html2canvas";
import { Button, Card } from "@mui/material";
import { IoSearchCircle } from "react-icons/io5";
import { jwtDecode } from "jwt-decode";
import Pagination from "@mui/material/Pagination";
import { useTripFiltersContext } from "./TripsFiltersContext";
import { useTripContext } from "../../../../Contexts/TripContext";
import { useDriverContext } from "../../../../Contexts/DriverContext";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Ride = () => {
  const [selectedTripsIds, setSelectedTripsIds] = useState([]);
  const [refreshStatistics, setRefreshStatistics] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCSVModelOpen, setIsCSVModelOpen] = useState(false);
  const [CSVModelMsg, setCSVModelMsg] = useState("");
  const [CSVResponseType, setCSVResponseType] = useState("");
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [driversArray, setDriversArray] = useState([]);
  const{allTrips,setAllTrips}=useTripContext()
  const{allDrivers}=useDriverContext()

  
  const {
    
    updateIsFilterApplied,
    IsDateSelected,
    updateIsDateSelected,
    SelectedDate,
    updateSelectedDate,
    currentTripPage,
    isPickUpDateSelected,
    updatePickUpDate,
    updateIsPickUpDateSelected,
    selectedPickUpDate,
    updateCurrentPage,
    getTripFiltersContext,
    selectedFilter,
    updateFilter,
  } = useTripFiltersContext();

  const data = [
    {
      name: "Jayceon Jackson",
      legID: "Q1858FB0A",
      pickup: "9:11 AM",
      dropoff: "9:44 AM",
      cancel: "",
      comments: "",
      signature: "J. Jackson",
      createdAt: new Date("2023-12-27T09:11:00"),
    },
    {
      name: "Jayceon Jackson",
      legID: "Q1858FB0B",
      pickup: "9:13 AM",
      dropoff: "9:45 AM",
      cancel: "",
      comments: "",
      signature: "J. Jackson",
      createdAt: new Date("2023-12-28T09:13:00"),
    },
    // Add more rows as needed
  ];
  
  const componentRef = useRef();
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const handleDateRangeSelection = () => {
    const startDate = prompt("Enter start date (YYYY-MM-DD):");
    const endDate = prompt("Enter end date (YYYY-MM-DD):");

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      setDateRange({ start, end });

      const filtered = data.filter(
        (row) => row.createdAt >= start && row.createdAt <= end
      );
      setFilteredData(filtered);
    }
  };

  const createPrintWindow = () => {
    let printContent = '';
    console.log("Drivers Array", driversArray);

    driversArray.forEach((driver, index) => {
        let transportationProvider = 'Random Transportation Provider'; // Set specific for each driver
        let vehicleId = 'triphog33'; // Set specific for each driver

        const { driverName, trips } = driver;
        console.log("Driver Signature Url", driver.signatureUrl);
        const driverSignatureUrl = driver.signatureUrl || '';
        const patientSignatureUrl = driver.patientSignatureUrl || '';

        const tripsContent = trips.map((trip, tripIndex) => `
            <tr>
                <td>${tripIndex + 1}</td>
                <td>${trip.pickUpTime || ''}</td>
                <td>${trip.legId || ''}</td>
                <td>${trip.patientName || ''}</td>
                <td>${trip.comments || ''}</td>
                <td>${trip.completedAt ? new Date(trip.completedAt).toDateString() + " " + new Date(trip.completedAt).toLocaleTimeString() : ' '}</td>
                <td>${trip.status == "Cancelled" || trip.status == "No Show" ? "Yes" : 'No'}</td>
                <td><img src="${trip.patientSignatureUrl}" alt="Patient Signature" width="100" /></td>
            </tr>
        `).join('');

        printContent += `
            <div class="driver-section">
                <div class="header">
                    <div class="box">
                        <strong>Transportation Provider:</strong>
                        <input type="text" id="transportationProvider${index}" disabled value="${transportationProvider}" oninput="updateProvider(${index}, this.value)">
                    </div>
                    <div class="box">
                        <strong>Date Range:</strong> ${new Date(logDateRange[0].startDate).toDateString() + " " + new Date(logDateRange[0].endDate).toDateString()}
                    </div>
                    <div class="box">
                        <strong>Driver Name:</strong> ${driverName}
                    </div>
                    <div class="box">
                        <strong>Vehicle ID:</strong>
                        <input type="text" id="vehicleId${index}" value="${vehicleId}" disabled oninput="updateVehicleId(${index}, this.value)">
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Pick Up Time</th>
                            <th>Leg ID</th>
                            <th>Patient Name</th>
                            <th>Comments</th>
                            <th>Drop Off Time</th>
                            <th>Cancel/No Show</th>
                            <th>Patient Signature</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tripsContent}
                    </tbody>
                </table>
                <div class="footer">
                    <p>This is to certify that the foregoing information is true, accurate, and complete. I know that payment of this claim will be from Federal and State Funds and that any falsification or concealment of any material fact may be prosecuted under Federal and State Laws.</p>
                    <div class="signature-box">
                        <p><strong>Driver Signature:</strong></p>
                        ${driverSignatureUrl ? `<img src="${driverSignatureUrl}" alt="Driver Signature" />` : `<p>No Signature</p>`}
                    </div>
                </div>
                <button onclick="window.print()">Download All Logs</button>
            </div>
        `;
    });

    const printWindow = window.open('', '', 'width=800,height=600');
    if (!printWindow) {
        console.error('Failed to open print window.');
        return;
    }

    printWindow.document.open();
    printWindow.document.write(`
        <html>
        <head>
            <title>Logs</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 20px;
                    border: 5px solid black; /* Black border around the whole window */
                    overflow-y: scroll; /* Enable scrolling */
                }
                .driver-section {
                    margin-bottom: 20px;
                    border: 2px solid #000; /* Border around each driver section */
                    padding: 10px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border: 1px solid #ccc;
                    padding: 2px; /* Minimal padding for height reduction */
                    text-align: left;
                    font-size: 12px; /* Reduced font size */
                    height: 13px; /* Minimum height */
                }
                th {
                    background-color: #f4f4f4;
                }
                img {
                    max-width: 100px;
                    max-height: 100px;
                    display: block;
                }
                .header {
                    display: grid;
                    grid-template-columns: 1fr 1fr; /* Two columns */
                    grid-gap: 10px;
                    margin-bottom: 20px;
                }
                .header .box {
                    border: 1px solid #000; /* Border around each box */
                    padding: 5px;
                    width: 100%; /* Full width of grid cell */
                }
                .footer {
                    margin-top: 20px;
                    text-align: center;
                }
                .footer .signature-box {
                    border: 2px solid #000;
                    padding: 10px;
                    width: 300px;
                    display: inline-block;
                    margin: 0 auto;
                }
            </style>
        </head>
        <body>
            ${printContent}
            <script>
                function updateProvider(index, value) {
                    document.querySelectorAll('#transportationProvider' + index).forEach(input => input.value = value);
                }
                function updateVehicleId(index, value) {
                    document.querySelectorAll('#vehicleId' + index).forEach(input => input.value = value);
                }
                function downloadDriver(index) {
                    const sections = document.querySelectorAll('.driver-section');
                    const section = sections[index];

                    // Create a new window for printing
                    const printWindow = window.open('', '', 'width=800,height=600');

                    // Add the content of the specific section to the new window
                    printWindow.document.write('<html><head><title>Driver Logs</title>');
                    printWindow.document.write('</head><body>');
                    printWindow.document.write('<h1>Driver Logs</h1>');
                    printWindow.document.write(section.outerHTML); // Get the HTML of the driver section
                    printWindow.document.write('</body></html>');

                    // Close the document to finish writing
                    printWindow.document.close();

                    // Wait for the content to fully load, then trigger the print dialog
                    printWindow.focus();
                    printWindow.print();

                    // Close the print window after printing
                    printWindow.onafterprint = function() {
                        printWindow.close();
                    };
                }
            </script>
        </body>
        </html>
    `);
    printWindow.document.close();
};


  const [drivers, setDrivers] = useState([]);
  const [trips, setTrips] = useState([]);
  const [pickUpTime, setPickUpTime] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [successfullyFetched, setSuccessfullyFetched] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState("today");
  const tripsPerPage = 25;
  useEffect(()=>{
    console.log("Selected Filter For Trips",selectedFilter)
    console.log("Trip Current Page",currentTripPage)
    setFilter(selectedFilter)
    setCurrentPage(currentTripPage)
    setSelectedDate(SelectedDate)
    setIsDateSelected(IsDateSelected)
  },[])
  
  let getTrips = async (selectedFilter, page) => {
    const formatDateToLocal = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
    
      return `${year}-${month}-${day}`;
    };
    
    const token = localStorage.getItem("token");
    setLoading(true);
    let response
    try {
      if(IsDateSelected)
      {
    const formattedDate = formatDateToLocal(isPickUpDateSelected?selectedPickUpDate:SelectedDate);

         response = await axios.get(
          `https://api.triphog.net/api/v1/trip/gettripsbydate/${formattedDate}?limit=${tripsPerPage}&page=${page}&isPickUpDateSelected=${isPickUpDateSelected}`,
          { headers: { Authorization: token } }
        );

      }
      else{

      
       response = await axios.get(
        `https://api.triphog.net/api/v1/trip/gettrips?page=${page}&limit=${tripsPerPage}&filter=${selectedFilter}`,
        { headers: { Authorization: token } }
      );
    }
      if (response.data.success) {
        setSuccessfullyFetched(true);
      }
      setTotalPages(response.data.totalPages);
      setTrips(response.data.trips);
      setLoading(false);
    } catch (e) {
      toast.error(e.response?e.response.data.message:e.message);
    }
  };
  useEffect(() => {
    const token=localStorage.getItem("token")
    
        if (token) {
          try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Convert to seconds
    
            if (decodedToken.exp && decodedToken.exp > currentTime) {
    getTrips(selectedFilter, currentTripPage);
              
            } else {
              console.log("Token has expired");
              navigate("/admin/login");
            }
          } catch (error) {
            console.log("Invalid token", error);
            navigate("/admin/login");
          }
        } else {
          navigate("/admin/login");
        }

  }, [IsDateSelected,isPickUpDateSelected,selectedFilter]);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAvailable, setShowAvailable] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async () => {
    setAnchorEl(null);
  };
  let navigate = useNavigate();

  let onClose = () => {
    setIsOpen(false);
  };
  let assignTrip = async () => {
    setIsOpen(false);
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (
        decodedUser.role == "User" &&
        decodedUser.role == "User" &&
        !checkPermission("Access To Assign Trip")
      ) {
        toast.warn("You Cannot Perform This Action.Please Contact Admin");
      } else {
        let response = await axios.post(
          `https://api.triphog.net/api/v1/trip/assigntrip/${selectedTripId}/${selectedDriverId}`,
          {},
          { headers: { Authorization: token } }
        );
        console.log(response.data);
        console.log(selectedDriverId);
        console.log(selectedTripId);
        const selectedDriver = allDrivers.find((driver) => driver._id === selectedDriverId);
        if (response.data.success) {
          setLoading(false);
          toast.success("Successfully Assigned!", { position: "top-center" });
        
          setAllTrips((prevTrips) =>
            prevTrips.map((trip) =>
              trip._id === selectedTripId ? { ...trip, status: "Assigned",driverName:selectedDriver.firstName+" "+selectedDriver.lastName } : trip
            )
          );
          setTrips((prevTrips) =>
            prevTrips.map((trip) =>
              trip._id === selectedTripId ? { ...trip, status: "Assigned",driverName:selectedDriver.firstName+" "+selectedDriver.lastName } : trip
            )
          );

          setIsOpen(false);
        } else {
          setLoading(false);
          toast.error("There Was An Error While Assigning Trip!", {
            position: "top-center",
          });
          setIsOpen(false);
        }
      }
    } catch (e) {
      toast.error("Someting Went Wrong While Assigning Trip!");
      setLoading(false);
      setIsOpen(false);
    }
  };
  const [selectedDriverId, setSelectedDriverId] = useState("");
  const [selectedTripId, setSelectedTripId] = useState("");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [confirmationBoxOpen, setConfirmationBoxOpen] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const [searchTerm, setSearchTerm] = useState("");
  let deleteSelectedTrips = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      let response = await axios.delete(
        `https://api.triphog.net/api/v1/trip/deleteselected`,
        {
          headers: { Authorization: token },
          data: { selectedTripsIds: selectedTripsIds },
        }
      );
      if (response.data.success) {
        setLoading(false);
        setRefreshStatistics(true);
        toast.success("Successufully Deleted!", { position: "top-center" });
        setRefreshStatistics(true);
        setSelectedTripsIds([]);
        setCurrentPage(1)
        setAllTrips((prevTrips) =>
          prevTrips.filter((trip) => !selectedTripsIds.includes(trip._id))
        );
        setTrips((prevTrips) =>
          prevTrips.filter((trip) => !selectedTripsIds.includes(trip._id))
        );
      } else {
        toast.error("There Was An Error While Deleting Trips!", {
          position: "top-center",
        });
        setLoading(false);
      }
    } catch (e) {
      toast.error("Something Went Wrong While Deleting Trips!");
      setLoading(false);
    }
  };
  let deleteTrip = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      let response = await axios.delete(
        `https://api.triphog.net/api/v1/trip/delete/${selectedTripId}`,
        { headers: { Authorization: token } }
      );
      if (response.data.success) {
        setLoading(false);
        setRefreshStatistics(true);
        toast.success("Successufully Deleted!", { position: "top-center" });
        setCurrentPage(1)
       // getAllTripsAndDrivers(filter, 1);
       setTrips(trips.filter((trip)=>{
        return (trip._id!=selectedTripId)
       }))
       setAllTrips((prevTrips) => prevTrips.filter((trip) => trip._id !== selectedTripId));
      } else {
        toast.error("There Was An Error While Deleting Trip!", {
          position: "top-center",
        });
        setLoading(false);
      }
    } catch (e) {
      toast.error("Something Went Wrong While Deleting Trip!");
      setLoading(false);
    }
  };
  const xlsxFileInputRef = useRef();
  const [xlsx, setXLSX] = useState();
  const [filteredTrips, setFilteredTrips] = useState([]);
  let handleDateChange = async (date, page) => {
    const formatDateToLocal = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
    
      return `${year}-${month}-${day}`;
    };
    setSelectedDate(date);
    setLoading(true);
    setIsDateSelected(true);
    updateIsDateSelected(true)
    console.log("Date Given",date)
    const formattedDate = formatDateToLocal(date)
    console.log(formattedDate);
    try {
      const token = localStorage.getItem("token");
      let response = await axios.get(
        `https://api.triphog.net/api/v1/trip/gettripsbydate/${formattedDate}?limit=${tripsPerPage}&page=${page}&isPickUpDateSelected=${isPickUpDateSelected}`,
        { headers: { Authorization: token } }
      );
      console.log(response.data);
      setTrips(response.data.trips);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (e) {
      toast.error("Something Went Wrong While Getting Trips");
    }
  };
  const [logDateRange, setLogDateRange] = useState([
    { startDate: new Date(), endDate: new Date(), key: "selection" },
  ]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  let handleDateRangeClose = () => {
    setIsDateRangeOpen(false);
  };

  
  const token = localStorage.getItem("token");
  
   
   let decodedUser = {};
   
   if (token) {
     try {
       const decoded = jwtDecode(token);
   
       // Check if the token is expired
       const currentTime = Date.now() / 1000; // Convert to seconds
       if (decoded.exp && decoded.exp > currentTime) {
         decodedUser = decoded;
       } else {
         console.log("Token has expired");
         navigate("/admin/login")
       }
     } catch (error) {
       navigate("/admin/login")
       console.log("Invalid token", error);
     }
   }
   
   if (decodedUser) {
     console.log("Decoded User:", decodedUser);
   } else {
     navigate("/admin/login")
     console.log("No valid token available, user not decoded.");
   }

  let checkPermission = (permission) => {
    console.log("Decoded User Permissions", decodedUser);
    if (decodedUser.role == "Admin") {
      return false;
    } else {
      return decodedUser.accessibilities.includes(permission);
    }
  };
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  if (decodedUser.role == "User" && !checkPermission("Access To View Trips")) {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">
            Unauthorized
          </h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the
            administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  } else {
    return (
      
      <>
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px] r-top">
          {loading && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <CircularProgress size={60} thickness={5} color="inherit" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedTripsIds.length > 0 ? (
             <button
             onClick={deleteSelectedTrips}
             className="bg-deepBlue text-white mb-[10px] h-[40px] w-[150px] rounded-lg shadow-lg transition-transform transform duration-300 hover:scale-105 hover:bg-deepBlue"
           >
             Delete Selected
           </button>
           
            ) : (
              <></>
            )}
          </div>
          <Dialog open={isDateRangeOpen} onClose={handleDateRangeClose}>
            <DialogTitle>Select Date Range</DialogTitle>
            <DialogContent>
              <DateRangePicker
                onChange={(item) => {
                  console.log("Selected Date Range");
                  console.log(item.selection);
                  let startDate = new Date(item.selection.startDate);
                  let endDate = new Date(item.selection.endDate);
                  console.log("Start Date", startDate);
                  console.log("End Date", endDate);
                  let filteredTrips = allTrips.filter((trip) => {
                    // console.log(trip.createdAt.getTime()>=startDate.getTime())
                    // console.log(trip.createdAt.getTime()<=endDate.getTime())
                    // console.log("Created AT",trip.createdAt)
                    let tripCreatedAt = new Date(trip.createdAt);
                    return (
                      tripCreatedAt.getTime() >= startDate.getTime() &&
                      tripCreatedAt.getTime() <= endDate.getTime()
                    );
                  });
                  console.log("Selected Date Range Trips", filteredTrips);
                  let driversForLogs = [];
                  let driversWithAssignedTrips = [];
                  for (let trip of filteredTrips) {
                    if (trip.driverRef.length > 0) {
                      let alreadyAdded = false;
                      for (let driver of driversWithAssignedTrips) {
                        if (trip.driverRef == driver.ref) {
                          alreadyAdded = true;
                        }
                      }
                      if (!alreadyAdded) {
                        driversWithAssignedTrips.push({
                          ref: trip.driverRef,
                          name: trip.driverName,
                          signatureUrl: trip.driverSignatureUrl,
                        });
                      }
                    }
                  }
                  for (let driver of driversWithAssignedTrips) {
                    let driverTrips = filteredTrips.filter((trip) => {
                      return trip.driverRef == driver.ref;
                    });
                    driversForLogs.push({
                      driverName: driver.name,
                      trips: driverTrips,
                      signatureUrl: driver.signatureUrl,
                    });
                  }
                  console.log("Driver For Logs");
                  console.log(driversForLogs);
                  setDriversArray(driversForLogs);
                  setFilteredTrips(filteredTrips);
                  setLogDateRange([item.selection]);
                }}
                ranges={logDateRange}
                direction="horizontal"
                rangeColors={["#30325E"]}
              />
            </DialogContent>
            <DialogActions>
              <button
                style={{
                  width: "100px",
                  color: "white",
                  borderRadius: "5px",
                  backgroundColor: "#30325E",
                }}
                onClick={handleDateRangeClose}
              >
                Cancel
              </button>
              <button
                style={{
                  width: "100px",
                  color: "white",
                  borderRadius: "5px",
                  backgroundColor: "#30325E",
                }}
                disabled={
                  decodedUser.role == "User" &&
                  !checkPermission("Access To Download Logs")
                }
                variant="contained"
                onClick={() => {
                  createPrintWindow();
                }}
              >
                Download PDF
              </button>
            </DialogActions>
          </Dialog>
          <Dialog open={isCSVModelOpen}>
            <DialogContent>
              <DialogTitle>CSV File Upload</DialogTitle>
              <Card>
                {isCSVLoading ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    style={{
                      color: CSVResponseType == "success" ? "green" : "red",
                    }}
                  >
                    {CSVModelMsg}
                  </Typography>
                )}
              </Card>
              <DialogActions>
                <Button
                  onClick={() => {
                    setIsCSVModelOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen}>
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DialogTitle style={{ color: "red",fontFamily:"Cabin" }}>
                Do You Really Want To Delete?
              </DialogTitle>
              <DialogActions>
                <Button
                  style={{ fontWeight: "bold",fontFamily:"Cabin" }}
                  onClick={() => {
                    deleteTrip();
                    setIsDialogOpen(false);
                  }}
                >
                  OK
                </Button>
                <Button
                  style={{ fontWeight: "bold",fontFamily:"Cabin" }}
                  onClick={() => {
                    setIsDialogOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <ToastContainer />
          <div className="flex  justify-between items-center w-[100%] rides_page">
            <h1 className="laptop:text-lap_c text-[40px] font-[600] driver_page_heading">
              Trips
            </h1>
            <div className="gap-[20px] trips_page">
              <button
                disabled={
                  decodedUser.role == "User" &&
                  !checkPermission("Access To Upload Trips")
                }
                className=" bg-[#cecdcd] mr-[20px] text-[black] text-[25px] laptop:text-lap_b laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] rounded-[10px] cursor-pointer"
                onClick={() => {
                  xlsxFileInputRef.current.click();
                }}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={xlsxFileInputRef}
                  onChange={(e) => {
                    async function bookTripsUsingCSV() {
                      setLoading(true);
                      let XLSX = e.target.files[0];
                      let formData = new FormData();
                      try {
                        formData.append("xlsx", XLSX);
                        let response = await axios.post(
                          "https://api.triphog.net/api/v1/trip/booktripsusingCSV",
                          formData,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                              Authorization: localStorage.getItem("token"),
                            },
                          }
                        );
                        console.log("Response", response);
                        if (response.status == 200) {
                          toast.success("Successfully Uploaded CSV!");
                          setLoading(false);
                          setRefreshStatistics(true);
                          getTrips(filter, 1);
                        } else {
                          setLoading(false);

                          toast.error("Sorry!CSV Could Not Be Uploaded!");
                        }
                      } catch (e) {
                        setLoading(false);
                        toast.error(
                          "Something Went Wrong While Uploading!" + e.message
                        );
                      }
                    }
                    bookTripsUsingCSV();
                  }}
                />
                <span className="flex items-center gap-3 ">
                  Upload csv File{" "}
                  <span className="text-lap_d">
                    <MdOutlineFileUpload />
                  </span>
                </span>
              </button>
              <button
                className=" float-right bg-[#30325E] text-[white] text-[25px] laptop:text-lap_b laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] rounded-[10px] cursor-pointer"
                onClick={() => {
                  setIsDateRangeOpen(true);
                }}
              >
                <span className="flex items-center gap-3">
                  Download <MdOutlineCloudDownload />
                </span>
              </button>
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-[10px] filter_input">
            <Link
              to={"/admin/addnewtrip"}
              className=" float-right bg-[#30325E] text-[white] text-[25px] laptop:text-lap_b laptop:py-[8px] px-[26px] laptop:px-[14px] py-[15px] rounded-[10px] cursor-pointer add_new_user_btn"
            >
              Add New Trip
            </Link>
            <div>
            <button
  className={`rounded-lg px-6 py-3 font-semibold transition-all duration-300 transform ${
    isPickUpDateSelected
      ? "bg-deepBlue text-white border border-deepBlue scale-105 shadow-md"
      : "bg-white text-gray-700 border border-gray-300 hover:shadow-lg"
  }`}
  onClick={() => updateIsPickUpDateSelected(!isPickUpDateSelected)}
>
  Filter By Pick Up Date
</button>

            </div>
            <div className="r-wi-100">
              <div className="float-right flex gap-[15px] r-wi-100">
                <div className="user_page_filter">
                  <div>
                    <div className="float-left flex gap-[15px]">
                     {
                      isPickUpDateSelected? <DatePicker
                      className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] focus:border-[#E4E4E4] focus:border-solid focus:border-[1.4px] focus:rounded-[10px]"
                      selected={selectedPickUpDate}
                      onChange={(date) => {
                        console.log("Handle Date Change", date);
                        updateIsFilterApplied(true)
                        updateCurrentPage(1)
                        updateSelectedDate(date)
                        updatePickUpDate(date)
                        updateIsPickUpDateSelected(true)
                        updateIsDateSelected(true)
                        handleDateChange(date, 1);
                      }}
                      dateFormat="yyyy-MM-dd"
                    />: <DatePicker
                    className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] focus:border-[#E4E4E4] focus:border-solid focus:border-[1.4px] focus:rounded-[10px]"
                    selected={selectedDate}
                    onChange={(date) => {
                      console.log("Handle Date Change", date);
                      updateIsFilterApplied(true)
                      updateCurrentPage(1)
                      updateIsPickUpDateSelected(false)
                      updateSelectedDate(date)
                      updateIsDateSelected(true)
                      handleDateChange(date, 1);
                    }}
                    dateFormat="yyyy-MM-dd"
                  />
                     }
                    </div>
                  </div>
                  <button
                    onClick={handleClick}
                    className="bg-deepBlue text-white h-[35px] w-[100px] rounded-lg "
                  >
                    <FilterListIcon />
                    Filter
                  </button>
                  <button className=" bg-[white] text-[#30325E] p-[12px] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] rounded-[10px] text-[25px] laptop:text-lap_b cursor-pointer  border-[1.4px] border-[#E4E4E4] border-solid dots_btn">
                    
                  </button>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleClose()}
                  PaperProps={{
                    style: {
                      maxHeight: "200px",
                      width: "250px",
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "today" && isDateSelected == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("today");
                      handleClose(null);
                      setIsDateSelected(false);
                      setCurrentPage(1)
                      updateIsDateSelected(false)
                      updateSelectedDate(new Date())
                      updateCurrentPage(1)
                      updateFilter("today")
                      updateIsFilterApplied(true)
                      getTrips("today", 1);
                    }}
                  >
                    <h5>Today</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "weekly" && isDateSelected == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("weekly");
                      handleClose(null);
                      setIsDateSelected(false);
                      updateSelectedDate(new Date())
                     
                      setCurrentPage(1)
                      updateIsDateSelected(false)
                      updateCurrentPage(1)
                      updateFilter("weekly")
                      updateIsFilterApplied(true)
                     
                      getTrips("weekly", 1);
                    }}
                  >
                    <h5>Last 7 Days</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "monthly" && isDateSelected == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("monthly");
                      handleClose(null);
                      setIsDateSelected(false);
                      setCurrentPage(1)
                      updateIsDateSelected(false)
                      updateSelectedDate(new Date())
                     
                      updateCurrentPage(1)
                      updateFilter("monthly")
                      updateIsFilterApplied(true)
                      getTrips("monthly", 1);
                    }}
                  >
                    <h5>Last 30 Days</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "all time" && isDateSelected == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("all time");
                      handleClose(null);
                      setIsDateSelected(false);
                      setCurrentPage(1)
                     
                      updateIsDateSelected(false)
                      updateSelectedDate(new Date())
                      updateCurrentPage(1)
                      updateFilter("all time")
                      updateIsFilterApplied(true)
                      getTrips("all time", 1);
                    }}
                  >
                    <h5>All Time</h5>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <div>
            <div>
              <Dialog open={confirmationBoxOpen}>
                <DialogContent>
                  <DialogTitle sx={{fontFamily:"Cabin"}}>
                    Are Your Sure To Assign The Trip To{" "}
                    <p style={{ color: "darkblue",fontFamily:"Cabin" }}>{selectedDriver} ?</p>
                  </DialogTitle>
                  <DialogActions>
                    <Button
                    sx={{fontFamily:"Cabin"}}
                      onClick={(e) => {
                        e.preventDefault();
                        assignTrip();
                        setConfirmationBoxOpen(false);
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                    sx={{fontFamily:"Cabin"}}
                      onClick={() => {
                        setConfirmationBoxOpen(false);
                      }}
                    >
                      Cancel
                    </Button>{" "}
                  </DialogActions>
                </DialogContent>
              </Dialog>
              <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle sx={{fontFamily:"Cabin",fontWeight:"bold"}}>Assign Driver</DialogTitle>
                <DialogContent>
                  {/* <TextField
                    fullWidth
                    label="Search"
                    variant="outlined"
                    onChange={(e) => {
                      let searchResults = allDrivers.filter((driver) => {
                        return (driver.firstName + driver.lastName)
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase());
                      });
                      setDrivers(searchResults);
                    }}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <button
                    onClick={() => {
                      async function getAvailableDrivers() {
                        let ShowAvailable = !showAvailable;
                        console.log(ShowAvailable);

                        if (ShowAvailable) {
                          const token = localStorage.getItem("token");
                          let response = await axios.get(
                            "https://api.triphog.net/api/v1/driver/getavailabledrivers",
                            {
                              params: {
                                pickUpDate,
                                pickUpTime,
                                appointmentTime,
                              },
                              headers: { Authorization: token },
                            }
                          );
                          console.log(response.data);
                          // setDrivers(availableDrivers)
                        } else {
                          setDrivers(allDrivers);
                        }
                        setShowAvailable(!showAvailable);
                      }
                      getAvailableDrivers();
                    }}
                    style={{
                      backgroundColor: showAvailable
                        ? "darkblue"
                        : "whitesmoke",
                      margin: "auto",
                      width: "130px",
                      height: "30px",
                      borderRadius: "30px",
                      color: showAvailable ? "white" : "black",
                    }}
                  >
                    {showAvailable ? "Showing Available" : "Show Available"}
                  </button>
                  <Divider style={{ margin: "20px 0" }} /> */}
                  <List>
                    {allDrivers.map((driver) => (
                      <ListItem key={driver.id} button>
                        <ListItemText sx={{fontFamily:"Cabin"}}
                          primary={driver.firstName + " " + driver.lastName}
                        />
                        <CircleIcon
                          style={{
                            color: driver.isAvailable ? "green" : "red",
                            marginRight: "10px",
                          }}
                        />
                        <Button
                          onClick={() => {
                            setSelectedDriverId(driver._id);
                            setSelectedDriver(
                              driver.firstName + " " + driver.lastName
                            );
                            setConfirmationBoxOpen(true);
                          }}
                          variant="contained"
                          style={{
                            fontFamily:"Cabin",
                            backgroundColor: "darkblue",
                            color: "white",
                          }}
                        >
                          Assign
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button sx={{fontFamily:"Cabin",color:"black",fontWeight:"bold"}} onClick={onClose}>
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>

              <div className="overflow-scroll   rounded-xl mt-[10px] border-[2px] w-[720px] border-solid border-[#B0B0B0]">
                <table className="min-w-full divide-y divide-gray-200 mt-[20px] border-separate">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <input type="checkbox" onChange={(e)=>{
                          if(e.target.checked==false)
                          {
                            setSelectedTripsIds([])
                          }
                          else{
                            let _selectedIds=[]
                            for(let trip of trips){

                              _selectedIds.push(trip._id)
                            }
                            setSelectedTripsIds(_selectedIds)
                          }
                        }} />
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Patient Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Driver Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Patient Type
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Pick Up Address
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Drop Off Address
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        PickUp Date
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Appointment Time
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Pickup Time
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-left text-[10px] font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {trips.map((trip) => (
                      <tr className="cursor-pointer">
                        <td className="px-2 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            checked={selectedTripsIds.includes(trip._id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                let isSelected = false;
                                for (let selectedTripId of selectedTripsIds) {
                                  if (selectedTripId == trip._id) {
                                    isSelected = true;
                                  }
                                }
                                if (isSelected) {
                                  let _selectedTripsIds =
                                    selectedTripsIds.filter(
                                      (selectedTripId) => {
                                        return selectedTripId != trip._id;
                                      }
                                    );
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedTripsIds
                                  );
                                  setSelectedTripsIds(_selectedTripsIds);
                                } else {
                                  let _selectedTripsIds =
                                    selectedTripsIds.concat(trip._id);
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedTripsIds
                                  );

                                  setSelectedTripsIds(_selectedTripsIds);
                                }
                              } else {
                                let isSelected = false;
                                for (let selectedTripId of selectedTripsIds) {
                                  if (selectedTripId == trip._id) {
                                    isSelected = true;
                                  }
                                }
                                if (isSelected) {
                                  let _selectedTripsIds =
                                    selectedTripsIds.filter(
                                      (selectedTripId) => {
                                        return selectedTripId != trip._id;
                                      }
                                    );
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedTripsIds
                                  );
                                  setSelectedTripsIds(_selectedTripsIds);
                                }
                              }
                            }}
                          />
                        </td>
                        <td className="px-2 py-4 whitespace-nowrap">
                          <div className="ml-4">
                            <div
                              onClick={() =>
                                navigate(`/admin/trip/${trip._id}`)
                              }
                              className="text-[10px] font-medium text-gray-900"
                            >
                              {`${trip.patientName}`}
                            </div>
                          </div>
                        </td>
                        <td className="px-2 py-4 whitespace-nowrap">
                          <h2>
                            {trip.driverName === "" ? (
                              <button
                                onClick={() => {
                                  setSelectedTripId(trip._id);
                                  setPickUpDate(trip.pickUpDate);
                                  setPickUpTime(trip.pickUpTime);
                                  setAppointmentTime(trip.appointmentTime);
                                  setIsOpen(true);
                                }}
                                className="bg-deepBlue"
                                style={{
                                  color: "white",
                                  borderRadius: "10px",
                                  width: "100px",
                                  fontSize: "12px",
                                }}
                              >
                                Assign
                              </button>
                            ) : (
                              <h2
                                className="text-[12px]"
                                onClick={() => {
                                  navigate(`/admin/trip/${trip._id}`);
                                }}
                              >
                                {trip.driverName}
                              </h2>
                            )}
                          </h2>
                        </td>
                        <td
                          onClick={() => navigate(`/admin/trip/${trip._id}`)}
                          className="px-2 py-4 whitespace-nowrap text-[12px] text-gray-500"
                        >
                          {trip.patientType}
                        </td>
                        <td
                          onClick={() => navigate(`/admin/trip/${trip._id}`)}
                          className="px-2 py-4 whitespace-nowrap text-[12px] text-gray-500"
                        >
                          {trip.pickUpAddress.slice(0, 13)}...
                        </td>
                        <td
                          onClick={() => navigate(`/admin/trip/${trip._id}`)}
                          className="px-2 py-4 whitespace-nowrap text-[12px] text-gray-500"
                        >
                          {trip.dropOffAddress.slice(0, 13)}...
                        </td>
                        <td
                          onClick={() => navigate(`/admin/trip/${trip._id}`)}
                          className="px-2 py-4 whitespace-nowrap text-[12px] text-gray-500"
                        >
                          {trip.pickUpDate}
                        </td>
                        <td
                          onClick={() => navigate(`/admin/trip/${trip._id}`)}
                          className="px-2 py-4 whitespace-nowrap text-[12px] text-gray-500"
                        >
                          {trip.appointmentTime}
                        </td>
                        <td
                          onClick={() => navigate(`/admin/trip/${trip._id}`)}
                          className="px-2 py-4 whitespace-nowrap text-[13px] text-gray-500"
                        >
                          {trip.pickUpTime}
                        </td>
                        <td
                          className="py-4 px-2 border-b border-gray-200"
                          onClick={() => navigate(`/admin/trip/${trip._id}`)}
                        >
                         <span className={`${
    trip.status === "Completed"
      ? "text-white bg-[#90EE90]"
      : trip.status === "Cancelled"
      ? "text-white bg-[#F08080]"
      : trip.status === "On Route"
      ? "text-white bg-orange-500"
      : "text-black bg-[#FFFF99]"
  } flex items-center py-2 px-2 rounded-full text-[10px] justify-center`}
>
  <GoDotFill /> {trip.status}
</span>

                        </td>
                        <td className="px-2 py-4 whitespace-nowrap text-[17px] font-medium flex gap-4 pt-[30px]">
                          <FaPencil
                            onClick={() => {
                              if (
                                decodedUser.role === "User" &&
                                !checkPermission("Access To Edit Trip")
                              ) {
                                toast.warn(
                                  "You Cannot Perform This Action. Please Contact Admin"
                                );
                              } else {
                                navigate(`/admin/edittrip/${trip._id}`);
                              }
                            }}
                          />
                          <span className="text-[red] text-[17px] mb-[12px] cursor-pointer">
                            <RiDeleteBin5Line
                              onClick={() => {
                                if (
                                  decodedUser.role === "User" &&
                                  !checkPermission("Access To Delete Trip")
                                ) {
                                  toast.warn(
                                    "You Cannot Perform This Action. Please Contact Admin"
                                  );
                                } else {
                                  setSelectedTripId(trip._id);
                                  setIsDialogOpen(true);
                                }
                              }}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-center items-center mt-6">
                <div className="flex justify-center my-5">
                  <Pagination
                    count={totalPages} // Total number of pages
                    page={currentPage} // Current active page
                    onChange={(event, value) => {
                      setCurrentPage(value); // Update the page
                      updateCurrentPage(value)
                      if (isDateSelected) {
                        handleDateChange(selectedDate, value);
                      } else {
                        getTrips(filter, value); // Fetch drivers for the new page
                      }
                    }}
                    variant="outlined"
                    color="primary"
                  />
                </div>
              </div>

              {/* {status && (
        <Modal status={status} setStatus={setStatus} message={modalMessage} />
      )} */}
            </div>
          </div>
        </div>
        <div className="mt-[-80px]" >
          <RideSideBar />
        </div>
      </>
    );
  }
};

export default Ride;
