import React, { useRef } from "react";
import { CiMenuKebab, CiSearch } from "react-icons/ci";
import { MdOutlineCloudDownload } from "react-icons/md";
import DriverData from "./DriverData";
import { FaPencil } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

import { RiDeleteBin5Line } from "react-icons/ri";
// import { useAllAdminsContext } from "../../../Providers/SuperAdmin/AllAdminsProvider";
import { Link, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  setRef,
} from "@material-ui/core";
import DriverSideBar from "./DriverSideBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "@mui/material/Pagination";

// import { useDeleteAdminContext } from "../../../Providers/SuperAdmin/DeleteAdminProvider";
import Modal from "../../../Modal";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useTripContext } from "../../../../Contexts/TripContext";
import { useDriverContext } from "../../../../Contexts/DriverContext";
const Driver = () => {
  const{allDrivers,setAllDrivers}=useDriverContext()
  const{allTrips}=useTripContext()
  const[allTimeTrips,setAllTimeTrips]=useState([])
  const [selectedDriversIds, setSelectedDriversIds] = useState([]);
  // const [allTrips, setAllTrips] = useState([]);
  const [refreshStatistics, setRefreshStatistics] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  let navigate = useNavigate();
  const [driverId, setDriverId] = useState("");
  const [filter, setFilter] = useState("all time");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  
  let deleteSelected = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      let response = await axios.delete(
        `https://api.triphog.net/api/v1/driver/deleteselected`,
        {
          headers: { Authorization: token },
          data: { selectedDriversIds: selectedDriversIds },
        }
      );
      if (response.data.success) {
        setSelectedDriversIds([]);
        setCurrentPage(1)
        getDrivers(filter, 1);
        setAllDrivers((prevDrivers) =>
          prevDrivers.filter((driver) => !selectedDriversIds.includes(driver._id))
        );
        setDrivers((prevDrivers) =>
          prevDrivers.filter((driver) => !selectedDriversIds.includes(driver._id))
        );

        setLoading(false);
        toast.success("Successfully Deleted!", { position: "top-center" });
      } else {
        setLoading(false);
        toast.error("Error While Deleting Drivers!", {
          position: "top-center",
        });
      }
    } catch (e) {
      setLoading(false);
      toast.error("Error While Deleting Drivers!");
    }
  };
  let deleteDriver = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      let response = await axios.delete(
        `https://api.triphog.net/api/v1/driver/delete/${driverId}`,
        { headers: { Authorization: token } }
      );
      if (response.data.success) {
        setCurrentPage(1)
        setDrivers(drivers.filter((driver)=>{return(driver._id!=driverId)}))
        setAllDrivers((prevDrivers) => prevDrivers.filter((driver) => driver._id !== driverId));
        setLoading(false);
      
        toast.success("Successfully Deleted!", { position: "top-center" });
      } else {
        setLoading(false);
        toast.error("Error While Deleting Driver!", { position: "top-center" });
      }
    } catch (e) {
      setLoading(false);
      toast.error("Error While Deleting Driver!");
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const driversPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  let getHoursRidden = (driverId) => {
    let hoursRidden = 0;
    let completedTrips = allTimeTrips.filter((trip) => {
      return trip.driverRef == driverId && trip.status == "Completed";
    });
    for (let completedTrip of completedTrips) {
      hoursRidden = hoursRidden + completedTrip.timeTaken;
    }
    return hoursRidden;
  };
  let getDrivers = async (selectedFilter, page) => {
    setIsSearchEnabled(false);
    try {
      const token = localStorage.getItem("token");
      setLoading(true);
      const response = await axios.get(
        `https://api.triphog.net/api/v1/driver/getdrivers?page=${page}&limit=${driversPerPage}&filter=${selectedFilter}`,
        { headers: { Authorization: token } }
      );
      
      
      setDrivers(response.data.drivers);
    
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Something Went Wrong!");
    }
  };
  useEffect(()=>{
    if(allTrips){
      setAllTimeTrips(allTrips)
    }
  },[allTrips])
  useEffect(() => {
    const token = localStorage.getItem("token");
        
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
        
                if (decodedToken.exp && decodedToken.exp > currentTime) {
                
                  getDrivers("all time", 1);
                  
                } else {
                  console.log("Token has expired");
                  navigate("/admin/login");
                }
              } catch (error) {
                console.log("Invalid token", error);
                navigate("/admin/login");
              }
            } else {
              navigate("/admin/login");
            }
    
  }, []);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  let handleDateChange = async (date, page) => {
    setSelectedDate(date);
    setIsSearchEnabled(false);
    setIsDateSelected(true);
    setLoading(true);
    const formattedDate = date.toISOString().split("T")[0];
    console.log(formattedDate);
    const token = localStorage.getItem("token");
    let response = await axios.get(
      `https://api.triphog.net/api/v1/driver/getdriversbydate/${formattedDate}?limit=${driversPerPage}&page=${page}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log(response.data);
    setLoading(false);
    setTotalPages(response.data.totalPages);
    setDrivers(response.data.drivers);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (filter) => {
    setAnchorEl(null);
  };
  const tableRef = useRef(null);
  //   const { setQuery, query } = useAllAdminsContext();
  const printTable = () => {
    const tableData = document.querySelector("table").cloneNode(true);
    const rows = tableData.querySelectorAll("tr");

    // Remove the "Actions" column from each row
    rows.forEach((row) => {
      const cells = row.querySelectorAll("td");
      if (cells.length > 5) {
        row.removeChild(cells[cells.length - 1]);
      }
    });

    const printWindow = window.open("", "", "height=800,width=800");
    printWindow.document.write(
      "<html><head><title>Print Table</title></head><body>"
    );
    printWindow.document.write(tableData.outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const token = localStorage.getItem("token");
   
   let decodedUser = {};
   
   if (token) {
     try {
       const decoded = jwtDecode(token);
   
       // Check if the token is expired
       const currentTime = Date.now() / 1000; // Convert to seconds
       if (decoded.exp && decoded.exp > currentTime) {
         decodedUser = decoded;
       } else {
         console.log("Token has expired");
         navigate("/admin/login")
       }
     } catch (error) {
       navigate("/admin/login")
       console.log("Invalid token", error);
     }
   }
   
   if (decodedUser) {
     console.log("Decoded User:", decodedUser);
   } else {
     navigate("/admin/login")
     console.log("No valid token available, user not decoded.");
   }

  let checkPermission = (permission) => {
    console.log("Decoded User Permissions", decodedUser);
    if (decodedUser.role == "Admin") {
      return false;
    } else {
      return decodedUser.accessibilities.includes(permission);
    }
  };
  if (
    decodedUser.role == "User" &&
    !checkPermission("Access To View Drivers")
  ) {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">
            Unauthorized
          </h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the
            administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px] driv_top">
          <div className="flex w-full justify-between items-center ">
            <h1 className="laptop:text-lap_c text-[40px] font-[600] driver_page_heading">
              Drivers
            </h1>
            {loading && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <CircularProgress size={60} thickness={5} color="inherit" />
              </div>
            )}

            {/* <button
            className=" float-right bg-[#30325E] text-[white] text-[25px] laptop:text-lap_b laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] rounded-[10px] cursor-pointer"
            onClick={printTable}
          >
            <span className="flex items-center gap-3">
              Download <MdOutlineCloudDownload />
            </span>
          </button> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedDriversIds.length > 0 ? (
              <button
                onClick={deleteSelected}
                className="bg-deepBlue text-white mb-[10px] h-[30px] w-[150px] rounded-lg"
              >
                Delete Selected
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="flex w-full justify-between items-center mt-[10px] drivers_parent">
            <Link
              to={"/admin/addnewdriver"}
              className=" float-right bg-[#30325E] text-[white] text-[25px] laptop:text-lap_b laptop:py-[8px] px-[26px] laptop:px-[14px] py-[15px] rounded-[10px] cursor-pointer add_new_user_btn"
            >
              Add New Driver
            </Link>
            <div className="d_filter">
              <div className="float-right flex gap-[15px] driver_filter filter_input">
                <input
                  className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] focus:border-[#E4E4E4] focus:border-solid focus:border-[1.4px] focus:rounded-[10px]"
                  type="text"
                  placeholder={((<CiSearch />), " Search by Name")}
                  onChange={(e) => {
                    if (e.target.value.length == 0) {
                      console.log("Setting Search Enable To False");
                      setIsSearchEnabled(false);
                    } else {
                      setIsSearchEnabled(true);
                    }
                    setSearchQuery(e.target.value);
                    let searchResults = allDrivers.filter((user) => {
                      return (
                        user.firstName
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase()) ||
                        user.lastName
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      );
                    });

                    const indexOfLastRecord = 1 * driversPerPage;
                    const indexOfFirstRecord =
                      indexOfLastRecord - driversPerPage;

                    // Get the records for the current page
                    const recordsToShow = searchResults.slice(
                      indexOfFirstRecord,
                      indexOfLastRecord
                    );

                    // Calculate total number of pages
                    const totalPages = Math.ceil(
                      searchResults.length / driversPerPage
                    );
                    setCurrentPage(1);
                    setTotalPages(totalPages);
                    setDrivers(recordsToShow);
                  }}
                />

                <div className="user_page_filter">
                  <div>
                    <div className="float-left flex gap-[15px]">
                      <DatePicker
                        className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] focus:border-[#E4E4E4] focus:border-solid focus:border-[1.4px] focus:rounded-[10px]"
                        selected={selectedDate}
                        onChange={(date) => {
                          console.log("Handle Date Change", date);
                          handleDateChange(date, 1);
                        }}
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>

                  <button
                    variant="contained"
                    onClick={handleClick}
                    className="bg-deepBlue text-white h-[35px] w-[100px] rounded-lg"
                  >
                    <FilterListIcon />
                    Filter
                  </button>
                  <button className=" bg-[white] text-[#30325E] p-[12px] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] rounded-[10px] text-[25px] laptop:text-lap_b cursor-pointer  border-[1.4px] border-[#E4E4E4] border-solid dots_btn">
                    <CiMenuKebab />
                  </button>
                </div>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleClose(null)}
                  PaperProps={{
                    style: {
                      maxHeight: "200px",
                      width: "250px",
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "today" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("today");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getDrivers("today",1);
                    }}
                  >
                    <h5>Today</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "weekly" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("weekly");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getDrivers("weekly",1);
                    }}
                  >
                    <h5>Last 7 Days</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "monthly" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("monthly");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getDrivers("monthly",1);
                    }}
                  >
                    <h5>Last 30 Days</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "all time" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("all time");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getDrivers("all time",1);
                    }}
                  >
                    <h5>All Time</h5>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <div>
            <div>
              <Dialog open={isDialogOpen}>
                <DialogContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DialogTitle style={{ color: "red" }}>
                    Do You Really Want To Delete?
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      style={{ fontWeight: "bold" }}
                      onClick={() => {
                        deleteDriver();
                        setIsDialogOpen(false);
                      }}
                    >
                      OK
                    </Button>
                    <Button
                      style={{ fontWeight: "bold" }}
                      onClick={() => {
                        setIsDialogOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>
              <ToastContainer />
              <div className="overflow-x-auto mt-[20px]">
                <table className="min-w-full border-separate border-spacing-0 border-[2px] border-solid border-[#B0B0B0] rounded-lg shadow-sm overflow-hidden drivers_table">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b-2 border-gray-300">
                        {/* <input type="checkbox" onChange={(e)=>{
           
          }} /> */}
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b-2 border-gray-300">
                        Ref No
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b-2 border-gray-300">
                        Driver Name
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b-2 border-gray-300">
                        Email
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b-2 border-gray-300">
                        Vehicle
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b-2 border-gray-300">
                        Total Hours Ridden
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b-2 border-gray-300">
                        Status
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b-2 border-gray-300">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {drivers.map((driver) => (
                      <tr
                        key={driver._id}
                        className="hover:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                      >
                        <td className="px-4 py-4 whitespace-nowrap border-b border-gray-300">
                          <input
                            checked={selectedDriversIds.includes(driver._id)}
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                let isSelected = false;
                                for (let selectedDriverId of selectedDriversIds) {
                                  if (selectedDriverId == driver._id) {
                                    isSelected = true;
                                  }
                                }
                                if (isSelected) {
                                  let _selectedDriversIds =
                                    selectedDriversIds.filter(
                                      (selectedDriverId) => {
                                        return selectedDriverId != driver._id;
                                      }
                                    );
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedDriversIds
                                  );
                                  setSelectedDriversIds(_selectedDriversIds);
                                } else {
                                  let _selectedDriversIds =
                                    selectedDriversIds.concat(driver._id);
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedDriversIds
                                  );

                                  setSelectedDriversIds(_selectedDriversIds);
                                }
                              } else {
                                let isSelected = false;
                                for (let selectedDriverId of selectedDriversIds) {
                                  if (selectedDriverId == driver._id) {
                                    isSelected = true;
                                  }
                                }
                                if (isSelected) {
                                  let _selectedDriversIds =
                                    selectedDriversIds.filter(
                                      (selectedDriverId) => {
                                        return selectedDriverId != driver._id;
                                      }
                                    );
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedDriversIds
                                  );

                                  setSelectedDriversIds(_selectedDriversIds);
                                }
                              }
                            }}
                          />
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-gray-300">
                          <div
                            className="text-sm font-medium text-gray-900"
                            onClick={() =>
                              navigate(`/admin/driver/${driver._id}`)
                            }
                          >
                            {driver._id.slice(0,5)}
                          </div>
                        </td>
                        <td
                          className="px-4 py-4 whitespace-nowrap border-b border-gray-300"
                          onClick={() =>
                            navigate(`/admin/driver/${driver._id}`)
                          }
                        >
                          <h2 className="font-semibold text-gray-800">{`${driver.firstName} ${driver.lastName}`}</h2>
                        </td>
                        <td
                          className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-gray-300"
                          onClick={() =>
                            navigate(`/admin/driver/${driver._id}`)
                          }
                        >
                          {driver.EMailAddress}
                        </td>
                        <td
                          className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-gray-300"
                          onClick={() =>
                            navigate(`/admin/driver/${driver._id}`)
                          }
                        >
                          {driver.vehicleName}
                        </td>
                        <td
                          className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-gray-300"
                          onClick={() =>
                            navigate(`/admin/driver/${driver._id}`)
                          }
                        >
                          {getHoursRidden(driver._id).toFixed(2)}
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-gray-300">
                        <span
  className={`${
    driver.status === "Available"
      ? "bg-[#E9FFEF] text-[#409261]"
      : "bg-yellow-200 text-black"
  } flex items-center py-2 w-[120px] px-4 rounded-xl text-xs justify-center`}
>
  <GoDotFill className="mr-2" /> {driver.status}
</span>

                        </td>
                        <td className="px-4 py-4 whitespace-nowrap border-b border-gray-300">
                          <div className="flex items-center gap-4">
                            <FaPencil
                              className="text-blue-500 hover:text-blue-700 transition duration-150"
                              onClick={() => {
                                if (
                                  decodedUser.role === "User" &&
                                  !checkPermission("Access To Edit Driver")
                                ) {
                                  toast.warn(
                                    "You Cannot Edit Driver. Ask Admin To Allow You To Perform This Action"
                                  );
                                } else {
                                  navigate(`/admin/editdriver/${driver._id}`);
                                }
                              }}
                            />
                            <RiDeleteBin5Line
                              className="text-red-500 hover:text-red-700 transition duration-150 cursor-pointer"
                              onClick={() => {
                                if (
                                  decodedUser.role === "User" &&
                                  !checkPermission("Access To Delete Driver")
                                ) {
                                  toast.warn(
                                    "You Cannot Delete Driver. Ask Admin To Allow You To Perform This Action"
                                  );
                                } else {
                                  setIsDialogOpen(true);
                                  setDriverId(driver._id);
                                }
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-center items-center mt-6">
                <div className="flex justify-center my-5">
                  <Pagination
                    count={totalPages} // Total number of pages
                    page={currentPage} // Current active page
                    onChange={(event, value) => {
                      setCurrentPage(value); // Update the page
                      console.log("Search Query", searchQuery);
                      if (isSearchEnabled) {
                        let searchResults = allDrivers.filter((user) => {
                          return (
                            user.firstName
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase()) ||
                            user.lastName
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          );
                        });
                        console.log("Search Results", searchResults);

                        const indexOfLastRecord = value * driversPerPage;
                        const indexOfFirstRecord =
                          indexOfLastRecord - driversPerPage;

                        // Get the records for the current page
                        const recordsToShow = searchResults.slice(
                          indexOfFirstRecord,
                          indexOfLastRecord
                        );
                        console.log("Records To Show", recordsToShow);
                        // Calculate total number of pages
                        const totalPages = Math.ceil(
                          searchResults.length / driversPerPage
                        );

                        setTotalPages(totalPages);
                        setDrivers(recordsToShow);
                      } else if (isDateSelected) {
                        handleDateChange(selectedDate, value);
                      } else {
                        getDrivers(filter, value); // Fetch drivers for the new page
                      }
                    }}
                    variant="outlined"
                    color="primary"
                  />
                </div>
              </div>
              {/* {status && (
        <Modal status={status} setStatus={setStatus} message={modalMessage} />
      )} */}
            </div>
          </div>
        </div>
        <div className="mt-[-80px]">
          <DriverSideBar refreshStatistics={refreshStatistics} />
        </div>
      </>
    );
  }
};

export default Driver;
