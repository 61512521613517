import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// Create the context
const TripContext = createContext();
// Custom provider component
export const TripProvider = ({ children }) => {
    const navigate=useNavigate()
 const[allTrips,setAllTrips]=useState([])
 const token=localStorage.getItem("token")

 const getAllTrips=async()=>{
    try{
        const response=await axios.get(
            `https://api.triphog.net/api/v1/trip/gettrips`,
            { headers: { Authorization: token } }
          );
          console.log("Trips Context Response",response.data)
          if(response.data.success){
            setAllTrips(response.data.trips)
          }
          else{
            console.error("Error fetching trips!")
          }
    }
    catch(e){
        console.error(e.response?e.response.data.message:e.message)
    }
 }
 useEffect(()=>{
    if(token)
    {
    const decodedToken=jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
            
    if (decodedToken.exp && decodedToken.exp > currentTime) {
     getAllTrips()
                      
  }
   else
   {
    navigate("/admin/login");
   }
    }
    
 },[token])
  return (
    <TripContext.Provider value={{allTrips,setAllTrips}}>
      {children}
    </TripContext.Provider>
  );
};

// Custom hook for using the context
export const useTripContext = () => useContext(TripContext);
