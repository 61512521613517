import React, { useRef } from "react";
import { CiMenuKebab, CiSearch } from "react-icons/ci";
import { MdOutlineCloudDownload, MdOutlineFileUpload } from "react-icons/md";
import PatientData from "./PatientData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaPencil } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { RiDeleteBin5Line } from "react-icons/ri";
// import { useAllAdminsContext } from "../../../Providers/SuperAdmin/AllAdminsProvider";
import { Link, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import FilterListIcon from "@mui/icons-material/FilterList";
import Pagination from "@mui/material/Pagination";

import { toast, ToastContainer } from "react-toastify";
// import { useDeleteAdminContext } from "../../../Providers/SuperAdmin/DeleteAdminProvider";
import Modal from "../../../Modal";
import { useEffect, useState } from "react";
import axios from "axios";
import PatientSideBar from "./PatientSideBar";
import { jwtDecode } from "jwt-decode";
import { useSocket } from "../../../SocketContext/SocketContext";
import { usePatientContext } from "../../../../Contexts/PatientContext";
const Patient = () => {
  const{allPatients,setAllPatients}=usePatientContext()
  const socket=useSocket()
  console.log("Socket Instance On Patient Side",socket)
  const [selectedPatientsIds, setSelectedPatientsIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState("all time");
  const [isDateSelected, setIsDateSelected] = useState(false);
  const patientsPerPage = 25;
  const [allTimePatients, setAllTimePatients] = useState([]);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchQuery, setSearchQuery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  let navigate = useNavigate();
  const [refreshStatistics, setRefreshStatistics] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [patientId, setPatientId] = useState("");
  let getAllPatients = async (selectedFilter, page) => {
    try {
      const token = localStorage.getItem("token");
      setIsSearchEnabled(false);
      setLoading(true);
      const response = await axios.get(
        `https://api.triphog.net/api/v1/patient/getall?page=${page}&limit=${patientsPerPage}&filter=${selectedFilter}`,
        { headers: { Authorization: token } }
      );
      
      console.log(response.data);
      setTotalPages(response.data.totalPages);
      setPatients(response.data.patients);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Something Went Wrong While Getting Patients!");
    }
  };
  let deleteSelected = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      let response = await axios.delete(
        `https://api.triphog.net/api/v1/patient/deleteselected`,
        {
          headers: { Authorization: token },
          data: { selectedPatientsIds: selectedPatientsIds },
        }
      );
      if (response.data.success) {
        setCurrentPage(1)
        setAllPatients((prevPatients) =>
          prevPatients.filter((patient) => !selectedPatientsIds.includes(patient._id))
        );
        setPatients((prevPatients) =>
          prevPatients.filter((patient) => !selectedPatientsIds.includes(patient._id))
        );
        setLoading(false);
        
        setSelectedPatientsIds([]);
        toast.success("Successfully Deleted!", { position: "top-center" });
      } else {
        setLoading(false);
        toast.error("Error While Deleting!", { postion: "top-center" });
      }
    } catch (e) {
      setLoading(false);
      toast.error("Something Sent Wrong!");
    }
  };
  let deletePatient = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      let response = await axios.delete(
        `https://api.triphog.net/api/v1/patient/delete/${patientId}`,
        { headers: { Authorization: token } }
      );
      if (response.data.success) {
        setCurrentPage(1)
        setPatients(patients.filter((patient)=>{return(patient._id!=patientId)}))
        setAllPatients((prevPatients) => prevPatients.filter((patient) => patient._id !== patientId));
        setLoading(false);
        toast.success("Successfully Deleted!", { position: "top-center" });
      } else {
        setLoading(false);
        toast.error("Error While Deleting!", { postion: "top-center" });
      }
    } catch (e) {
      setLoading(false);
      toast.error("Error While Deleting Patient!");
    }
  };
  useEffect(()=>{
    if(allPatients){
      setAllTimePatients(allPatients)
    }
  },[allPatients])
  useEffect(() => {
    const token = localStorage.getItem("token");
        
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
        
                if (decodedToken.exp && decodedToken.exp > currentTime) {
    getAllPatients("all time", 1);
        
                  
                } else {
                  console.log("Token has expired");
                  navigate("/admin/login");
                }
              } catch (error) {
                console.log("Invalid token", error);
                navigate("/admin/login");
              }
            } else {
              navigate("/admin/login");
            }
  }, []);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (filter) => {
    setAnchorEl(null);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());

  let handleDateChange = async (date, page) => {
    try {
      setSelectedDate(date);
      setIsDateSelected(true);
      setIsSearchEnabled(false);
      setLoading(true);
      const formattedDate = date.toISOString().split("T")[0];
      console.log(formattedDate);
      const token = localStorage.getItem("token");
      let response = await axios.get(
        `https://api.triphog.net/api/v1/patient/getpatientsbydate/${formattedDate}?limit=${patientsPerPage}$page=${page}`,
        { headers: { Authorization: token } }
      );
      console.log(response.data);
      setTotalPages(response.data.totalPages);
      setPatients(response.data.patients);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Something Went Wrong While Getting Patients");
    }
  };
  const tableRef = useRef(null);
  //   const { setQuery, query } = useAllAdminsContext();
  const printTable = () => {
    const tableData = document.querySelector("table").cloneNode(true);
    const rows = tableData.querySelectorAll("tr");

    // Remove the "Actions" column from each row
    rows.forEach((row) => {
      const cells = row.querySelectorAll("td");
      if (cells.length > 5) {
        row.removeChild(cells[cells.length - 1]);
      }
    });

    const printWindow = window.open("", "", "height=800,width=800");
    printWindow.document.write(
      "<html><head><title>Print Table</title></head><body>"
    );
    printWindow.document.write(tableData.outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const token = localStorage.getItem("token");
   
   let decodedUser = {};
   
   if (token) {
     try {
       const decoded = jwtDecode(token);
   
       // Check if the token is expired
       const currentTime = Date.now() / 1000; // Convert to seconds
       if (decoded.exp && decoded.exp > currentTime) {
         decodedUser = decoded;
       } else {
         console.log("Token has expired");
         navigate("/admin/login")
       }
     } catch (error) {
       navigate("/admin/login")
       console.log("Invalid token", error);
     }
   }
   
   if (decodedUser) {
     console.log("Decoded User:", decodedUser);
   } else {
     navigate("/admin/login")
     console.log("No valid token available, user not decoded.");
   }

  let checkPermission = (permission) => {
    console.log("Decoded User Permissions", decodedUser);
    if (decodedUser.role == "Admin") {
      return false;
    } else {
      return decodedUser.accessibilities.includes(permission);
    }
  };
  if (
    decodedUser.role == "User" &&
    !checkPermission("Access To View Patients")
  ) {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">
            Unauthorized
          </h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the
            administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px] user-page">
          <div className="flex  justify-between items-center w-[100%]">
            <h1 className="laptop:text-lap_c text-[40px] font-[600] driver_page_heading">
              Patients
            </h1>
            <div className="gap-[20px] "></div>
          </div>
          {loading && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <CircularProgress size={60} thickness={5} color="inherit" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedPatientsIds.length > 0 ? (
              <button
                onClick={deleteSelected}
                className="bg-deepBlue text-white mb-[10px] h-[30px] w-[150px] rounded-lg"
              >
                Delete Selected
              </button>
            ) : (
              <></>
            )}
          </div>

          <div className="flex w-full justify-between items-center mt-[10px] filter_parent">
            <Link
              to={"/admin/addpatient"}
              className=" float-right bg-[#30325E] text-[white] text-[25px] laptop:text-lap_b laptop:py-[8px] px-[26px] laptop:px-[14px] py-[15px] rounded-[10px] cursor-pointer add_new_user_btn"
            >
              Add New Patient
            </Link>
            <div className="r-wi-100">
              <div className="float-right flex gap-[15px] filter_input">
                <input
                  className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] focus:border-[#E4E4E4] focus:border-solid focus:border-[1.4px] focus:rounded-[10px]"
                  type="text"
                  placeholder={((<CiSearch />), " Search by Name")}
                  onChange={(e) => {
                    if (e.target.value.length == 0) {
                      console.log("Setting Search Enable To False");
                      setIsSearchEnabled(false);
                    } else {
                      setIsSearchEnabled(true);
                    }
                    setSearchQuery(e.target.value);
                    let searchResults = allTimePatients.filter((user) => {
                      return (
                        user.firstName
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase()) ||
                        user.lastName
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      );
                    });

                    const indexOfLastRecord = 1 * patientsPerPage;
                    const indexOfFirstRecord =
                      indexOfLastRecord - patientsPerPage;

                    // Get the records for the current page
                    const recordsToShow = searchResults.slice(
                      indexOfFirstRecord,
                      indexOfLastRecord
                    );

                    // Calculate total number of pages
                    const totalPages = Math.ceil(
                      searchResults.length / patientsPerPage
                    );
                    setCurrentPage(1);
                    setTotalPages(totalPages);
                    setPatients(recordsToShow);
                  }}
                />
                <div className="user_page_filter">
                  <div>
                    <div className="float-left flex gap-[15px]">
                      <DatePicker
                        className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] focus:border-[#E4E4E4] focus:border-solid focus:border-[1.4px] focus:rounded-[10px]"
                        selected={selectedDate}
                        onChange={(date) => {
                          console.log("Handle Date Change", date);
                          handleDateChange(date, 1);
                        }}
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                  <button
                    className="bg-deepBlue text-white h-[35px] w-[100px] rounded-lg"
                    onClick={handleClick}
                  >
                    <FilterListIcon />
                    Filter
                  </button>
                  <button className=" bg-[white] text-[#30325E] p-[12px] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] rounded-[10px] text-[25px] laptop:text-lap_b cursor-pointer  border-[1.4px] border-[#E4E4E4] border-solid dots_btn">
                    <CiMenuKebab />
                  </button>
                </div>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleClose(null)}
                  PaperProps={{
                    style: {
                      maxHeight: "200px",
                      width: "250px",
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "today" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("today");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getAllPatients("today",1);
                    }}
                  >
                    <h5>Today</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "weekly" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("weekly");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getAllPatients("weekly", 1);
                    }}
                  >
                    <h5>Last 7 Days</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "monthly" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("monthly");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getAllPatients("monthly", 1);
                    }}
                  >
                    <h5>Last 30 Days</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "all time" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("all time");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getAllPatients("all time", 1);
                    }}
                  >
                    <h5>All Time</h5>
                  </MenuItem>
                </Menu>
               
              </div>
            </div>
          </div>
          <div>
            <div>
              <Dialog open={isDialogOpen}>
                <DialogContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DialogTitle style={{ color: "red" }}>
                    Do You Really Want To Delete?
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      style={{ fontWeight: "bold" }}
                      onClick={() => {
                        deletePatient();
                        setIsDialogOpen(false);
                      }}
                    >
                      OK
                    </Button>
                    <Button
                      style={{ fontWeight: "bold" }}
                      onClick={() => {
                        setIsDialogOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>
              <ToastContainer />
              <div class="overflow-x-auto mt-[20px] border-[2px] border-solid border-[#B0B0B0] rounded-xl">
                <table class="min-w-full divide-y divide-gray-200 rounded-xl">
                  <thead class="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        {/* <input type="checkbox" /> */}
                      </th>
                      <th
                        scope="col"
                        class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        Ref no
                      </th>
                      <th
                        scope="col"
                        class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        Patient Name
                      </th>
                      <th
                        scope="col"
                        class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        Phone Number
                      </th>
                      <th
                        scope="col"
                        class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        Age
                      </th>
                      <th
                        scope="col"
                        class="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    {patients.map((patient) => (
                      <tr className="hover:bg-gray-50 transition duration-150 ease-in-out cursor-pointer">
                        <td class="px-4 py-4 whitespace-nowrap">
                          <input
                            type="checkbox"
                            checked={selectedPatientsIds.includes(patient._id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                let isSelected = false;
                                for (let selectedPatientId of selectedPatientsIds) {
                                  if (selectedPatientId == patient._id) {
                                    isSelected = true;
                                  }
                                }
                                if (isSelected) {
                                  let _selectedPatientsIds =
                                    selectedPatientsIds.filter(
                                      (selectedPatientId) => {
                                        return selectedPatientId != patient._id;
                                      }
                                    );
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedPatientsIds
                                  );
                                  setSelectedPatientsIds(_selectedPatientsIds);
                                } else {
                                  let _selectedPatientsIds =
                                    selectedPatientsIds.concat(patient._id);
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedPatientsIds
                                  );

                                  setSelectedPatientsIds(_selectedPatientsIds);
                                }
                              } else {
                                let isSelected = false;
                                for (let selectedPatientId of selectedPatientsIds) {
                                  if (selectedPatientId == patient._id) {
                                    isSelected = true;
                                  }
                                }
                                if (isSelected) {
                                  let _selectedPatientsIds =
                                    selectedPatientsIds.filter(
                                      (selectedPatientId) => {
                                        return selectedPatientId != patient._id;
                                      }
                                    );
                                  console.log(
                                    "Selected Users Ids",
                                    _selectedPatientsIds
                                  );

                                  setSelectedPatientsIds(_selectedPatientsIds);
                                }
                              }
                            }}
                          />
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap">
                          <div
                            class="text-sm font-medium text-gray-900"
                            onClick={() =>
                              navigate(`/admin/patient/${patient._id}`)
                            }
                          >
                            {`${patient._id.slice(0,5)}`}
                          </div>
                        </td>
                        <td
                          class="px-4 py-4 whitespace-nowrap"
                          onClick={() =>
                            navigate(`/admin/patient/${patient._id}`)
                          }
                        >
                          <h2 class="font-semibold text-gray-800">{`${patient.firstName} ${patient.lastName}`}</h2>
                        </td>
                        <td
                          class="px-4 py-4 whitespace-nowrap text-sm text-gray-500"
                          onClick={() =>
                            navigate(`/admin/patient/${patient._id}`)
                          }
                        >
                          {patient.EMailAddress}
                        </td>
                        <td
                          class="px-4 py-4 whitespace-nowrap text-sm text-gray-500"
                          onClick={() =>
                            navigate(`/admin/patient/${patient._id}`)
                          }
                        >
                          {patient.phoneNumber}
                        </td>
                        <td
                          class="px-4 py-4 whitespace-nowrap text-sm text-gray-500"
                          onClick={() =>
                            navigate(`/admin/patient/${patient._id}`)
                          }
                        >
                          {patient.age}
                        </td>
                        <td class="px-4 py-4 whitespace-nowrap text-lg font-medium flex gap-4">
                          <FaPencil
                            className="text-blue-500 hover:text-blue-700 transition duration-150"
                            onClick={() => {
                              if (
                                decodedUser.role == "User" &&
                                !checkPermission("Access To Edit Patient")
                              ) {
                                toast.warn(
                                  "You Cannot Perform This Action. Please Contact Admin"
                                );
                              } else {
                                navigate(`/admin/patient/edit/${patient._id}`);
                              }
                            }}
                          />
                          <RiDeleteBin5Line
                            className="text-red-500 hover:text-red-700 transition duration-150 cursor-pointer"
                            onClick={() => {
                              if (
                                decodedUser.role == "User" &&
                                !checkPermission("Access To Delete Patient")
                              ) {
                                toast.warn(
                                  "You Cannot Perform This Action. Please Contact Admin"
                                );
                              } else {
                                setPatientId(patient._id);
                                setIsDialogOpen(true);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-center items-center mt-6">
                <div className="flex justify-center my-5">
                  <Pagination
                    count={totalPages} // Total number of pages
                    page={currentPage} // Current active page
                    onChange={(event, value) => {
                      setCurrentPage(value); // Update the page
                      if (isSearchEnabled) {
                        let searchResults = allTimePatients.filter((user) => {
                          return (
                            user.firstName
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase()) ||
                            user.lastName
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          );
                        });
                        console.log("Search Results", searchResults);

                        const indexOfLastRecord = value * patientsPerPage;
                        const indexOfFirstRecord =
                          indexOfLastRecord - patientsPerPage;

                        // Get the records for the current page
                        const recordsToShow = searchResults.slice(
                          indexOfFirstRecord,
                          indexOfLastRecord
                        );
                        console.log("Records To Show", recordsToShow);
                        // Calculate total number of pages
                        const totalPages = Math.ceil(
                          searchResults.length / patientsPerPage
                        );
                        setTotalPages(totalPages);
                        setPatients(recordsToShow);
                      } else if (isDateSelected) {
                        handleDateChange(selectedDate, value);
                      } else {
                        getAllPatients(filter, value); // Fetch drivers for the new page
                      }
                    }}
                    variant="outlined"
                    color="primary"
                  />
                </div>
              </div>
              {/* {status && (
        <Modal status={status} setStatus={setStatus} message={modalMessage} />
      )} */}
            </div>
          </div>
        </div>
        <div className="mt-[-80px]">
           <PatientSideBar refreshStatistics={refreshStatistics} /> 
        </div>
      </>
    );
  }
};

export default Patient;
