import axios from "axios";
import { createContext, useEffect, useState } from "react";

// Create AdminContext
export const AdminContext = createContext(null);

export const AdminProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null); // State to hold admin data
  const [plan, setPlan] = useState("Basic");
  const token = localStorage.getItem("token"); // Get token from localStorage


  // Fetch admin data from the API
  const fetchAdminData = async () => {
    try {
      const response = await axios.get(
        `https://api.triphog.net/api/v1/superadmin/admin/getById/${token}`
      );
      console.log("Admin Response",response.data)
      setAdmin(response.data.data); // Set admin data from the API response
      setPlan(response.data.data.plan);
    } catch (error) {
      console.error("Error fetching admin data:", error);
      setAdmin(null); // Reset admin state in case of error
    }
  };

  // Fetch admin data when the component mounts
  useEffect(() => {
    if(token){
    fetchAdminData();
    }
  }, [token]);

  // Provide admin state and setAdmin function to the application
  return (
    <AdminContext.Provider value={{ admin, setAdmin, plan, setPlan }}>
      {children}
    </AdminContext.Provider>
  );
};
