import React, { useContext, useEffect, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { CiBrightnessUp, CiCreditCard2, CiLogin } from "react-icons/ci";
import { FaRegMoon } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineCalendarMonth, MdSpaceDashboard } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";
import { Lock } from '@mui/icons-material';
import { useLocation, useNavigate } from "react-router-dom";
import MobileNavBar from "./MobileNavBar";
import { Description, DriveEta, TripOriginTwoTone } from "@mui/icons-material";
import { MdGroup } from "react-icons/md";
import { Star } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PaymentIcon from "@mui/icons-material/Payment";
import SecurityIcon from '@mui/icons-material/Security';
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { AdminContext } from "../AdminContext/AdminContext";
const Header = () => {
  const {plan,admin}=useContext(AdminContext)
  const [isSubAdmin, setIsSubAdmin] = useState(false);
  let location = useLocation();
  const [menu, setMenu] = useState(false);
  const[hasBasicPlan,setHasBasicPlan]=useState(admin?.plan=="Basic")
  let navigate = useNavigate();
  const token=localStorage.getItem("token")
//  useEffect(()=>{
//  if(plan)
//  {
//  setHasBasicPlan(plan=="Basic")
//  }
//  },[plan])
  useEffect(() => {
    if(!token)
    {
      navigate("/admin/login")
    }
    else{
      
    let decodedUser = jwtDecode(localStorage.getItem("token"));
    if (decodedUser.role == "User") {
      setIsSubAdmin(true);
    }
  }
  }, []);
  const toggleMenu = () => {
    setMenu(!menu);
  };

  const iconStyle = (path) => ({
    backgroundColor: location.pathname === path ? "#30325E" : "transparent",
    borderRadius: "50%",
    padding: "10px", // Reduced padding
    color: location.pathname === path ? "white" : "lightslategray",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px", // Reduced width
    height: "35px", // Reduced height
    marginBottom: "8px", // Reduced gap between items
  });
  const jwt_Token = localStorage.getItem("token");

let decodedUser = {};

if (jwt_Token) {
  try {
    const decoded = jwtDecode(jwt_Token);

    // Check if the token is expired
    const currentTime = Date.now() / 1000; // Convert to seconds
    if (decoded.exp && decoded.exp > currentTime) {
      decodedUser = decoded;
    } else {
      console.log("Token has expired");
      navigate("/admin/login")
    }
  } catch (error) {
    navigate("/admin/login")
    console.log("Invalid token", error);
  }
}

if (decodedUser) {
  console.log("Decoded User:", decodedUser);
} else {
  navigate("/admin/login")
  console.log("No valid token available, user not decoded.");
}

  let checkPermission = (permission) => {
    console.log("Decoded User Permissions", decodedUser);
    if (decodedUser.role == "Admin") {
      return false;
    } else {
      return decodedUser.accessibilities.includes(permission);
    }
  };

  return (
<div className="relative">
    {menu && <MobileNavBar menu={menu} setMenu={setMenu} />}

    {/* Mobile */}
    <div className="laptop:hidden mt-[20px] mx-[-20px] text-[70px] pb-[20px] mb-[30px] border-solid border-b-[3px] border-b-[#959595] box-border">
      <div className="px-[40px] flex justify-between items-center my-[-12px]">
        <div className="ml-[-20px] siteLogo">
          <Link to={"/admin"}>
            <img
              src={require('./appimage.jpeg')}
              height={"250px"}
              width={"250px"}
              style={{ borderRadius: "5px" }}
              alt="Trip Hog"
            />
          </Link>
        </div>
        <div className="float-right nav_toggle_handle" onClick={toggleMenu}>
          <IoMdMenu />
        </div>
      </div>
    </div>

    {/* Laptop */}
    <div className="laptop:max-w-[120px] hidden bg-[#FFFFFF] laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 laptop:h-screen laptop:place-items-center laptop:grid">
      {/* Scrollable content */}
      <div
        className="laptop:pt-[20px] laptop:gap-y-[10px] laptop:place-items-center laptop:h-full laptop:overflow-y-hidden sidebar_div"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Top Section */}
        <div>
          <Tooltip title="Dashboard" placement="right">
            <div onClick={() => navigate("/admin")} style={iconStyle("/admin")}>
              <MdSpaceDashboard className="text-[20px]" />
            </div>
          </Tooltip>
          {!isSubAdmin && (
            <Tooltip title="Users" placement="right">
              <div
                onClick={() => navigate("/admin/users")}
                style={iconStyle("/admin/users")}
              >
                <RiUserFollowLine className="text-[20px]" />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Trips" placement="right">
            <div
              onClick={() => navigate("/admin/trips")}
              style={iconStyle("/admin/trips")}
            >
              <TripOriginTwoTone className="text-[20px]" />
            </div>
          </Tooltip>
          <Tooltip title="Meetings" placement="right">
            <div
              onClick={() => navigate("/admin/meeting")}
              style={iconStyle("/admin/meeting")}
            >
              <MdOutlineCalendarMonth className="text-[20px]" />
            </div>
          </Tooltip>
          <Tooltip title="Drivers" placement="right">
            <div
              onClick={() => navigate("/admin/drivers")}
              style={iconStyle("/admin/drivers")}
            >
              <DriveEta className="text-[20px]" />
            </div>
          </Tooltip>
          <Tooltip title="Patients" placement="right">
            <div
              onClick={() => navigate("/admin/patient")}
              style={iconStyle("/admin/patient")}
            >
              <MdGroup className="text-[20px]" />
            </div>
          </Tooltip>
          {isSubAdmin && checkPermission("Access To Payrol") ? (
  <Tooltip title="Payrol" placement="right">
    <div
      onClick={() => navigate("/admin/payrol")}
      style={iconStyle("/admin/payrol")}
    >
      <CiCreditCard2 className="text-[20px]" />
    </div>
  </Tooltip>
) : !isSubAdmin && admin?.plan === "Basic" ? null : (
  <Tooltip title="Payrol" placement="right">
    <div
      onClick={() => navigate("/admin/payrol")}
      style={iconStyle("/admin/payrol")}
    >
      <CiCreditCard2 className="text-[20px]" />
    </div>
  </Tooltip>
)}

          {(isSubAdmin &&
            checkPermission("Access To Download Logs")) ||
          (!isSubAdmin && (
            <Tooltip title="Logs" placement="right">
              <div
                onClick={() => navigate("/admin/triplogs")}
                style={iconStyle("/admin/triplogs")}
              >
                <Description className="text-[20px]" />
              </div>
            </Tooltip>
          ))}
          <Tooltip title="Chat" placement="right">
            <div
              onClick={() => navigate("/admin/chat")}
              style={iconStyle("/admin/chat")}
            >
              <AiOutlineMessage className="text-[20px]" />
            </div>
          </Tooltip>
          <Tooltip title="Settings" placement="right">
            <div
              onClick={() => navigate("/admin/settings")}
              style={iconStyle("/admin/settings")}
            >
              <IoSettingsOutline className="text-[20px]" />
            </div>
          </Tooltip>
          <Tooltip title="Reviews" placement="right">
            <div
              onClick={() => navigate("/admin/tripreviews")}
              style={iconStyle("/admin/tripreviews")}
            >
              <Star className="text-[20px]" />
            </div>
          </Tooltip>
          {(!isSubAdmin && admin?.plan !== "Basic") ? (
  <Tooltip title="My Documents" placement="right">
    <div
      onClick={() => navigate("/admin/documents")}
      style={iconStyle("/admin/documents")}
    >
      <Lock className="text-[20px]" />
    </div>
  </Tooltip>
) : null}


          {!isSubAdmin && (
            <Tooltip title="Payment" placement="right">
              <div
                onClick={() => navigate("/admin/plan")}
                style={iconStyle("/admin/plan")}
              >
                <PaymentIcon className="text-[20px]" />
              </div>
            </Tooltip>
          )}
        </div>

        {/* Bottom Section */}
        <div>
          <Tooltip title="Logout" placement="right">
            <div
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/admin/login");
              }}
              className="text-[20px] text-[#1F1C2E] cursor-pointer"
            >
              <CiLogin />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Header;
