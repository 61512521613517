import { IoArrowBackOutline } from "react-icons/io5";
// import { useAddNewAdminContext } from "../../../../Providers/SuperAdmin/AddNewAdminProvider";
import { MdLinkedCamera } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
// import AdminSideBar from "./AddNewAdminSideBar";
import { useLoadScript,Autocomplete } from "@react-google-maps/api";
import Modal from "../../../Modal";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useTripContext } from "../../../../Contexts/TripContext";
import { usePatientContext } from "../../../../Contexts/PatientContext";
const libraries = ['places'];


const AddNewRide = () => {
  const{allTrips,setAllTrips}=useTripContext()
  const{allPatients}=usePatientContext()
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCRDiod9UUH0SHTiIDg2k3fW_FtO0EekSg',
    libraries,
  });
  const [isOtherOptionSelected,setIsOtherOptionSelected]=useState(false)
  const pickupAutocompleteRef = useRef();
  const dropoffAutocompleteRef = useRef();

  const onLoadPickup = (autocomplete) => {
    pickupAutocompleteRef.current = autocomplete;
  };

  const onLoadDropoff = (autocomplete) => {
    dropoffAutocompleteRef.current = autocomplete;
  };

  const onPlaceChangedPickup = () => {
    if (pickupAutocompleteRef.current !== null) {
      const place = pickupAutocompleteRef.current.getPlace();
      setPickUpAddress(place.formatted_address);
    }
  };

  const onPlaceChangedDropoff = () => {
    if (dropoffAutocompleteRef.current !== null) {
      const place = dropoffAutocompleteRef.current.getPlace();
      setDropOffAddress(place.formatted_address);
    }
  };

  const[patientName,setPatientName]=useState("")
  const[patientPhoneNumber,setPatientPhoneNumber]=useState("")
  const[patientRef,setPatientRef]=useState("")
  const[pickUpDate,setPickUpDate]=useState("")
  const[pickUpTime,setPickUpTime]=useState("")
  const[appointmentTime,setAppointmentTime]=useState("") 
  const[pickUpAddress,setPickUpAddress]=useState("")
  const[dropOffAddress,setDropOffAddress]=useState("") 
  const[legId,setLegId]=useState("")
  const[patientType,setPatientType]=useState("Wheel Chair")
  const[patients,setPatients]=useState([])
  let navigate=useNavigate()
  let getAllPatients=async()=>{
    if(allPatients){
      setPatients(allPatients)
    }
  }
  const[loading,setLoading]=useState(false)
  useEffect(()=>{
    getAllPatients()
  },[allPatients])
  let addTrip=async()=>{
    setLoading(true)
    const token=localStorage.getItem("token")
    try{
      if(patientRef=="Other Option" || patientRef=="Choose Patient By Name" || patientRef=="Choose Patient By Ref")
      {
        console.log("There Is No Patient Ref")
        setPatientRef("")
      }
      let response=await axios.post("https://api.triphog.net/api/v1/trip/addtrip",{patientName,patientPhoneNumber,patientRef:(patientRef=="Other Option" || patientRef=="Choose Patient By Name" || patientRef=="Choose Patient By Ref"?"":patientRef),patientType,pickUpDate,pickUpDate,pickUpTime,appointmentTime,legId,pickUpAddress,dropOffAddress},{headers:{'Authorization':token}})
      console.log(response.data)
   if(response.data.success){
    console.log("New Added Trip Response",response.data)
    toast.success("Successfully booked trip!",{position:"top-right",style:{fontFamily:"Cabin",fontWeight:"bold"}})
    setAllTrips([...allTrips,response.data.trip])
    setLoading(false)
    setTimeout(()=>{
      navigate("/admin/trips")
    },2000)
   }
   else{
    toast.error("Trip could be not be booked!Make sure you fill all the fields!",{style:{fontFamily:"Cabin",fontWeight:"bold"},position:"top-right"})
    setLoading(false)
   }
  }
  catch(e){
    setLoading(false)
    toast.error(e.response?e.response.data.message:e.message,{style:{fontFamily:"Cabin",fontWeight:"bold"},position:"top-right"})
  }
  }
  const[decodedUser,setDecodedUser]=useState({})
  useEffect(()=>{
    const token = localStorage.getItem("token");
      
      
      if (token) {
        try {
          const decoded = jwtDecode(token);
      
          // Check if the token is expired
          const currentTime = Date.now() / 1000; // Convert to seconds
          if (decoded.exp && decoded.exp > currentTime) {
            setDecodedUser(decoded)
          } else {
            console.log("Token has expired");
            navigate("/admin/login")
          }
        } catch (error) {
          navigate("/admin/login")
          console.log("Invalid token", error);
        }
      }
      
      if (decodedUser) {
        console.log("Decoded User:", decodedUser);
      } else {
        navigate("/admin/login")
        console.log("No valid token available, user not decoded.");
      }

  },[navigate])
 

  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  if(decodedUser.role=="User" &&(!checkPermission("Access To Add Trip")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  else{
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center h-screen w-full bg-white">
        <p className="text-darkgray text-[30px] font-medium">Loading Maps...</p>
      </div>
    );
  }

 
  return (
    <>
      <div className="">
        <ToastContainer/>
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
        <button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",margin:"1px"}}>BACK</h5>
          
          </button>
          <div class="w-full rounded-sm  bg-cover bg-center bg-no-repeat items-center">
            
            
          </div>
          <form
  className="text-darkgray laptop:text-lap_b text-[30px]"
  onSubmit={(event) => {
    event.preventDefault();
  }}
>
  {/* Patient Ref and Patient Name Section */}
  <div className="laptop:flex gap-16 laptop:my-[16px]">
    {/* Patient Ref */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Patient Ref</label>
      <select
        value={patientRef}
        onChange={(event) => {
          const selectedValue = event.target.value;
          if (selectedValue === "Other Option") {
            setIsOtherOptionSelected(true);
            setPatientRef(selectedValue);
            setPatientName("Other Option");
            setPatientPhoneNumber("");
          } else {
            const patientId = selectedValue;
            if (patientId !== "Choose Patient By Ref") {
              const _patients = patients.filter((patient) => patient._id === patientId);
              const Patient = _patients[0];
              setPatientName(Patient.firstName + " " + Patient.lastName);
              setPatientPhoneNumber(Patient.phoneNumber);
              setPatientRef(patientId);
              setIsOtherOptionSelected(false);
            }
          }
        }}
        className="mt-[8px] border-[0.6px] cursor-pointer rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
      >
        <option value="Choose Patient By Ref">Choose Patient By Ref</option>
        {patients.map((patient) => (
          <option key={patient._id} value={patient._id}>
            {patient._id}
          </option>
        ))}
        <option value="Other Option">Other Option</option>
      </select>
    </div>

    {/* Patient Name */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Patient Name (Full)</label>
      {isOtherOptionSelected ? (
        <input
          type="text"
          placeholder="Enter Patient Name"
          value={patientName}
          onChange={(e) => setPatientName(e.target.value)}
          className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
        />
      ) : (
        <select
          value={patientName === "Other Option" ? "Other Option" : patientRef}
          onChange={(event) => {
            const patientId = event.target.value;
            if (patientId === "Other Option") {
              setPatientName("Other Option");
              setPatientRef("Other Option");
              setPatientPhoneNumber("");
              setIsOtherOptionSelected(true);
            } else if (patientId !== "Choose Patient By Name") {
              const _patients = patients.filter((patient) => patient._id === patientId);
              const Patient = _patients[0];
              setPatientName(Patient.firstName + " " + Patient.lastName);
              setPatientPhoneNumber(Patient.phoneNumber);
              setPatientRef(patientId);
            } else {
              setPatientRef("Choose Patient By Name");
            }
          }}
          className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
        >
          <option value="Choose Patient By Name">Choose Patient By Name</option>
          {patients.map((patient) => (
            <option key={patient._id} value={patient._id}>
              {patient.firstName + " " + patient.lastName}
            </option>
          ))}
          <option value="Other Option">Other Option</option>
        </select>
      )}
    </div>
  </div>

  {/* Patient Phone Number and Patient Type Section */}
  <div className="laptop:flex gap-16 laptop:my-[16px]">
    {/* Patient Phone Number */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Patient Phone Number</label>
      <input
        type="number"
        placeholder="546-933-2772"
        value={patientPhoneNumber}
        onChange={(event) => setPatientPhoneNumber(event.target.value)}
        className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
      />
    </div>

    {/* Patient Type */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Patient Type</label>
      <select
        value={patientType}
        onChange={(event) => setPatientType(event.target.value)}
        className="mt-[8px] border-[0.6px] cursor-pointer rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
      >
        <option disabled>Patient Type</option>
        <option value="Wheel Chair">Wheel Chair</option>
        <option value="Normal">Normal</option>
      </select>
    </div>
  </div>

  {/* Leg ID and Appointment Time Section */}
  <div className="laptop:flex gap-16 laptop:my-[16px]">
    {/* Leg ID */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Leg ID</label>
      <input
        type="text"
        placeholder="Q5469332171"
        onChange={(event) => setLegId(event.target.value)}
        className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
      />
    </div>

    {/* Appointment Time */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Appointment Time</label>
      <input
        type="time"
        onChange={(event) => setAppointmentTime(event.target.value)}
        className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
      />
    </div>
  </div>

  {/* Pickup Date and Pickup Time Section */}
  <div className="laptop:flex gap-16 laptop:my-[16px]">
    {/* Pickup Date */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Pickup Date</label>
      <input
        type="date"
        placeholder="12.02.2024"
        onChange={(event) => setPickUpDate(event.target.value)}
        className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
      />
    </div>

    {/* Pickup Time */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Pickup Time</label>
      <input
        type="time"
        onChange={(event) => setPickUpTime(event.target.value)}
        className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
      />
    </div>
  </div>

  {/* Pickup Address and Dropoff Address Section */}
  <div className="laptop:flex gap-16 laptop:my-[16px]">
    {/* Pickup Address */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Pickup Address</label>
      <Autocomplete onLoad={onLoadPickup} onPlaceChanged={onPlaceChangedPickup}>
        <input
          type="text"
          placeholder="H/No GH34"
          onChange={(e) => setPickUpAddress(e.target.value)}
          className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
        />
      </Autocomplete>
    </div>

    {/* Dropoff Address */}
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] w-[100%]">
      <label className="block mb-2 font-medium">Dropoff Address</label>
      <Autocomplete onLoad={onLoadDropoff} onPlaceChanged={onPlaceChangedDropoff}>
        <input
          type="text"
          placeholder="Hospital"
          onChange={(event) => setDropOffAddress(event.target.value)}
          className="mt-[8px] border-[0.6px] rounded-lg laptop:text-lap_b text-[30px] py-[12px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-deepBlue focus:ring-1 focus:ring-deepBlue transition-all"
        />
      </Autocomplete>
    </div>
  </div>

  {/* Submit Button */}
  <div className="flex justify-center items-center mt-8">
    {loading ? (
      <button className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-full py-[12px] px-[50px] flex items-center justify-center">
        <CircularProgress style={{ color: "white", height: "20px", width: "20px" }} thickness={8} />
      </button>
    ) : (
      <button
        onClick={(e) => {
          e.preventDefault();
          addTrip();
        }}
        type="submit"
        className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-full py-[12px] px-[50px] transition-all"
      >
        Submit
      </button>
    )}
  </div>
</form>

        </div>
      </div>
      
    </>
  );
}
};


export default AddNewRide;

/*

*/
