import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
const totalRecordsPerPage = 25;

const PaymentHistoryData = ({paymentHistory}) => {
  const navigate=useNavigate()
  console.log("Payment History",paymentHistory)
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [filter, setFilter] = useState("All");

  const getPaymentsHistory = async () => {
    
    try{
  
    
    setPayments(paymentHistory);
    setFilteredPayments(paymentHistory);
    setLoading(false);
  }
  catch(e){
    toast.error("Error In Payment History")
  }
  
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
        
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
        
                if (decodedToken.exp && decodedToken.exp > currentTime) {
    getPaymentsHistory();
        
                  
                } else {
                  console.log("Token has expired");
                  navigate("/admin/login");
                }
              } catch (error) {
                console.log("Invalid token", error);
                navigate("/admin/login");
              }
            } else {
              navigate("/admin/login");
            }
  }, [paymentHistory]);

  useEffect(() => {
   const token = localStorage.getItem("token");
       
           if (token) {
             try {
               const decodedToken = jwtDecode(token);
               const currentTime = Date.now() / 1000; // Convert to seconds
       
               if (decodedToken.exp && decodedToken.exp > currentTime) {
    filterPayments();
       
                 
               } else {
                 console.log("Token has expired");
                 navigate("/admin/login");
               }
             } catch (error) {
               console.log("Invalid token", error);
               navigate("/admin/login");
             }
           } else {
             navigate("/admin/login");
           } 

  }, [filter, payments]);

  const filterPayments = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);

    let filtered = payments;
    switch (filter) {
      case "Today":
        filtered = payments.filter((payment) =>
          new Date(payment.addedON).toDateString() === today.toDateString()
        );
        break;
      case "Last 7 Days":
        filtered = payments.filter(
          (payment) =>
            new Date(payment.addedON) >= sevenDaysAgo &&
            new Date(payment.addedON) <= today
        );
        break;
      case "Last 30 Days":
        filtered = payments.filter(
          (payment) =>
            new Date(payment.addedON) >= thirtyDaysAgo &&
            new Date(payment.addedON) <= today
        );
        break;
      default:
        break;
    }
    setFilteredPayments(filtered);
  };

  const totalPages = Math.ceil(filteredPayments.length / totalRecordsPerPage);
  const currentPayments = filteredPayments.slice(
    (currentPage - 1) * totalRecordsPerPage,
    currentPage * totalRecordsPerPage
  );

  return (
    <div className="font-cabin">
      <ToastContainer/>
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CircularProgress size={60} thickness={5} color="inherit" />
        </div>
      )}
      <div className="flex justify-end mb-2">
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="border border-gray-300 rounded-md p-2"
        >
          <option value="All">All</option>
          <option value="Today">Today</option>
          <option value="Last 7 Days">Last 7 Days</option>
          <option value="Last 30 Days">Last 30 Days</option>
        </select>
      </div>
      <table className="min-w-full divide-y divide-gray-200 overflow-x-auto mt-[20px] border-[2px] border-solid border-[#B0B0B0] rounded-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <input type="checkbox" />
            </th>
            <th
              scope="col"
              className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Payment ID
            </th>
            <th
              scope="col"
              className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Amount
            </th>
            <th
              scope="col"
              className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Payment Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentPayments.map((payment) => (
            <tr key={payment.id}>
              <td className="px-2 py-4 whitespace-nowrap">
                <input type="checkbox" />
              </td>
              <td className="px-2 py-4 whitespace-nowrap">
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900">
                    {`${payment.id}`}
                  </div>
                </div>
              </td>
              <td className="px-2 py-4 whitespace-nowrap">
                <h2>{payment.addedON.split("T")[0]}</h2>
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                {payment.amount}
              </td>
              <td className="py-4 px-2 border-b border-gray-200 ">
                <span
                  className={`${
                    payment.status === "Not Available"
                      ? "text-[#E42346] bg-[#DF367033]"
                      : payment.paymentStatus === "Available"
                      ? "text-[#409261] bg-[#E9FFEF]"
                      : "text-[#D98634] bg-[#FFF2DD]"
                  } flex items-center py-2 px-2 rounded-full text-xs justify-center`}
                >
                  <GoDotFill /> {payment.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center items-center mt-6">
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
          {Array.from({ length: totalPages }, (_, i) => (
            <div
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              className={`cursor-pointer ${
                currentPage === i + 1
                  ? "bg-gray-300"
                  : "bg-white hover:bg-gray-50"
              } relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:text-gray-500`}
            >
              {i + 1}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default PaymentHistoryData;
