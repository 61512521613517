import React, { useEffect, useState } from "react";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { FaCarSide } from "react-icons/fa6";
import axios from "axios";
import { Unarchive } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTripContext } from "../../../../Contexts/TripContext";
import { useDriverContext } from "../../../../Contexts/DriverContext";
const DriverSideBar = (props) => {
  const{allTrips}=useTripContext()
  const[allTimeTrips,setAllTimeTrips]=useState([])
  const{allDrivers}=useDriverContext()
  const[totalDrivers,setTotalDrivers]=useState([])
  const[availableDrivers,setAvailableDrivers]=useState([])
  const navigate=useNavigate()
  const[loading,setLoading]=useState(true)
  const[onRoute,setOnRouteDrivers]=useState([])
  let getAndSetDrivers=async()=>{
    setLoading(true)
    try{
    const token=localStorage.getItem("token")
   
    let pickUpDate=new Date().toDateString()
    let pickUpTime=new Date().toTimeString()
    let appointmentTime=""
    console.log(pickUpDate)
    console.log(pickUpTime)
    const availableDrivers=allDrivers.filter((driver)=>{return(driver.isAvailable)})
    let unAvailable=allDrivers.length-availableDrivers.length
    console.log(unAvailable)
    
    let onRoute=[]
    
    for(let driver of allDrivers)
      {
        let filteredTrips=allTimeTrips.filter((trip)=>{
          return(trip.driverRef==driver._id && trip.status=="On Route")
        })
        if(filteredTrips.length>0)
        {
          onRoute.push(driver)
        }
      }
      console.log("On Route Drivers",onRoute.length)
      setTotalDrivers(allDrivers.length)
      setAvailableDrivers(allDrivers.length-onRoute.length)
      setOnRouteDrivers(onRoute.length) 
    }
    catch(e){
      toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bolder"}})

    }
    finally{
      setLoading(false)
    }
  }
  useEffect(()=>{

    if(allTrips){
      setAllTimeTrips(allTrips)
    }
  },[allTrips])
  
  useEffect(()=>{
    const token = localStorage.getItem("token");
        
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
        
                if (decodedToken.exp && decodedToken.exp > currentTime) {
    getAndSetDrivers()
        
                  
                } else {
                  console.log("Token has expired");
                  navigate("/admin/login");
                }
              } catch (error) {
                console.log("Invalid token", error);
                navigate("/admin/login");
              }
            } else {
              navigate("/admin/login");
            }
  },[allTrips,allDrivers])
  if(loading){
    return(
    <div className="laptop:grid h-screen hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white ">
<div className="flex justify-center items-center h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-deepBlue"></div>
          </div>
          </div>
    
    )
  }
  else{
  return (
    <div className="h-screen laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white ">
      <div className="w-[100%]">
        <div className="flex float-right text-lap_e cursor-pointer">
          
        </div>
        <h2 className="text-lap_c mt-[60px] font-[600]">DRIVERS</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{totalDrivers}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{availableDrivers}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Available</span>
            </div>
            
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{onRoute}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">On Route</span>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  );
}
};

export default DriverSideBar;
