import React, { useEffect, useState } from "react";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { FaCarSide } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useTripContext } from "../../../../Contexts/TripContext";

const DriverSideBar = () => {
  const { allTrips } = useTripContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [totalTrips, setTotalTrips] = useState(0);
  const [assignedTrips, setAssignedTrips] = useState(0);
  const [notAssignedTrips, setNotAssignedTrips] = useState(0);
  const [nonResponsiveTrips, setNonResponsiveTrips] = useState(0);
  const [noShowTrips, setNoShowTrips] = useState(0);
  const [completedTrips, setCompletedTrips] = useState(0);
  const [cancelledTrips, setCancelledTrips] = useState(0);
  const [onRouteTrips, setOnRouteTrips] = useState(0);

  useEffect(() => {
    if (allTrips) {
      setTotalTrips(allTrips.length);
      setAssignedTrips(allTrips.filter((trip) => trip.status === "Assigned").length);
      setNotAssignedTrips(allTrips.filter((trip) => trip.status === "Not Assigned").length);
      setNonResponsiveTrips(allTrips.filter((trip) => trip.status === "Non Responsive").length);
      setNoShowTrips(allTrips.filter((trip) => trip.status === "No Show").length);
      setCompletedTrips(allTrips.filter((trip) => trip.status === "Completed").length);
      setCancelledTrips(allTrips.filter((trip) => trip.status === "Cancelled").length);
      setOnRouteTrips(allTrips.filter((trip) => trip.status === "On Route").length);
    }
  }, [allTrips]);

  if (!allTrips) {
    return (
      <div className="h-screen laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white min-h-screen ">
        <div className="flex justify-center items-center h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-deepBlue"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-screen laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white min-h-screen ">
        <div className="w-[100%]">
          <ToastContainer />
          <h2 className="text-lap_c mt-[60px] font-[600]">Total Trips</h2>
          <h2 className="text-lap_d font-[700] text-deepBlue">{totalTrips}</h2>
          <div className="grid  items-center my-[20px] gap-[10%]">
            <div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#409261] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-deepBlue text-lap_b">{assignedTrips}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">Assigned</span>
              </div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#D98634] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-deepBlue text-lap_b">{onRouteTrips}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">On Route</span>
              </div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#E42346] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-deepBlue text-lap_b">{completedTrips}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">Completed</span>
              </div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#409261] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-deepBlue text-lap_b">{cancelledTrips}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">Cancelled</span>
              </div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#D98634] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-deepBlue text-lap_b">{notAssignedTrips}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">Not Assigned</span>
              </div>
              <div className="flex items-center my-[10px]">
                <h3 className="text-[#E42346] text-lap_c">
                  <TbRectangleFilled />
                </h3>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-deepBlue text-lap_b">{nonResponsiveTrips}</span> &nbsp;&nbsp;{" "}
                <span className="text-darkgray text-lap_b">Non Responsive</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default DriverSideBar;